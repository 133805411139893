import React, { useContext, useEffect, useState } from 'react';
import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import { useNavigate } from 'react-router-dom';
import InputText from '../formik/InputText';
import InputSelect from '../formik/InputSelect';
import TableLoadingSkeleton from '../loading/TableLoadingSkeleton';
import SubmitButton from '../formik/SubmitButton';
import CardBox from '../common/CardBox';
import { UserContext } from '../../providers/UserProvider';

function EditInviteMemberForm() {
  let navigate = useNavigate();
  const {
    user,
    editInviteMember,
    getAccountTypes,
    getBranches,
    getStates,
    getClubs,
    clubs,
    pendingInvitesInfo,
    getPendingInvitesInfo,
    branches,
    accountTypes,
    states,
    loadingUsers,
  } = useContext(UserContext);
  const [years, setYears] = useState([]);

  const generateArrayOfYears = async () => {
    var max = new Date().getFullYear();
    var min = max - 100;
    var years = [];

    for (var i = max; i >= min; i--) {
      if (i <= 2010) {
        years.push(i);
      }
    }

    return years;
  };

  useEffect(() => {
    getPendingInvitesInfo(localStorage.getItem('invitationInfoId'));

    if (user.data.account_type_id == 1) {
      navigate('/dashboard');
    }
    generateArrayOfYears().then(data => {
      setYears(data);
      console.log(data);
    });
    getAccountTypes();
    getBranches();
    getClubs();
    getStates();
  }, []);

  return (
    <CardBox bg="gray.50" borderRadius="2xl" p={5}>
      {loadingUsers ? <TableLoadingSkeleton /> : null}
      {loadingUsers == false && pendingInvitesInfo && (
        <Formik
          initialValues={{
            id: pendingInvitesInfo.id,
            account_type_id: pendingInvitesInfo.account_type_id,
            club_id: pendingInvitesInfo.club_id,
            club_branch_id: pendingInvitesInfo.club_branch_id,
            club_state_id: pendingInvitesInfo.club_state_id,
            name: pendingInvitesInfo.name,
            email: pendingInvitesInfo.email,
            year_of_birth: pendingInvitesInfo.year_of_birth,
          }}
          validationSchema={SignupSchema}
          onSubmit={(values, actions) => {
            console.log(values);
            values.year_of_birth = values.year_of_birth;
            editInviteMember(values);
            setTimeout(() => {
              actions.setSubmitting(false);
              actions.resetForm();
            }, 1000);
          }}
        >
          {({ isSubmitting, values }) => (
            <Form>
              <InputSelect
                name="account_type_id"
                title="Member Type"
                options={accountTypes}
              />

              {values.account_type_id == 1 || values.account_type_id == 2 ? (
                <InputSelect name="club_id" title="Club" options={clubs} />
              ) : null}

              {values.account_type_id == 3 ? (
                <InputSelect
                  name="club_branch_id"
                  title="Branch"
                  options={branches}
                />
              ) : null}

              {values.account_type_id == 4 ? (
                <InputSelect
                  name="club_state_id"
                  title="State"
                  options={states}
                />
              ) : null}

              <InputText name="name" />
              <InputText name="email" type="email" />
              {years && (
                <InputSelect
                  name="year_of_birth"
                  title="Year of birth"
                  options={years}
                />
              )}
              <SubmitButton isSubmitting={isSubmitting} />
            </Form>
          )}
        </Formik>
      )}
    </CardBox>
  );
}

const SignupSchema = Yup.object().shape({
  account_type_id: Yup.string().required('Required'),
  name: Yup.string()
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
    .required('Required'),
  year_of_birth: Yup.number().required('Required'),
  email: Yup.string().email('Invalid email').required('Required'),
});

const userTypes = [
  {
    id: 1,
    name: 'Member',
  },
  {
    id: 2,
    name: 'Club Admin',
  },
  {
    id: 3,
    name: 'Branch Admin',
  },
  {
    id: 4,
    name: 'State Admin',
  },
  {
    id: 5,
    name: 'Super Admin',
  },
];

export default EditInviteMemberForm;
