import { useContext, useEffect } from 'react';
import { Box, SimpleGrid } from '@chakra-ui/react';
import ClubListsTable from '../../components/club/ClubListsTable';
import { AppContext } from '../../providers/AppProvider';
import { UserContext } from '../../providers/UserProvider';

function ClubLists() {
  const { user, getUserInfo, clubs, getClubs } = useContext(UserContext);

  useEffect(() => {
    if (clubs.length === 0) {
      getClubs();
    }
  }, []);
  return (
    <Box>
      <SimpleGrid columns={1} spacing="24px">
        <ClubListsTable />
      </SimpleGrid>
    </Box>
  );
}

export default ClubLists;
