import { Button, theme } from '@chakra-ui/react';

function SubmitButton({ title = 'Submit', isSubmitting }) {
  return (
    <Button
      mt={10}
      bgColor={theme.colors.cyan[600]}
      color="white"
      variant="solid"
      size="lg"
      isLoading={isSubmitting}
      loadingText="loading..."
      type="submit"
      w="100%"
    >
      {title}
    </Button>
  );
}

export default SubmitButton;
