import React from 'react';
import { Box } from '@chakra-ui/react';
import TextHeader from '../components/common/TextHeader';
import InviteMemberSignUpForm from '../components/forms/InviteMemberSignUpForm';

function InviteMemberSignUp() {
  return (
    <Box>
      <TextHeader
        title="Complete Sign Up"
        subtitle="Please set a password for your account."
      />
      <InviteMemberSignUpForm />
    </Box>
  );
}

export default InviteMemberSignUp;
