import { useContext, useMemo, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { IconButton, Tooltip } from '@chakra-ui/react';
import { TriangleDownIcon, TriangleUpIcon } from '@chakra-ui/icons';
import { useTable, useSortBy } from 'react-table';
import { UserContext } from '../../providers/UserProvider';
import ActivityButton from './ActivityButton';
import RepeatActivityButton from './RepeatActivityButton';
import TableLoadingSkeleton from '../loading/TableLoadingSkeleton';
import useWindowSize from '../../helper/useWindowSize';
import DataTable from '../common/DataTable';
import CardBox from '../common/CardBox';
import { AddIcon, EditIcon } from '@chakra-ui/icons';
import { useNavigate } from 'react-router-dom';
import { MdRefresh } from 'react-icons/md';

function ActivitiesListTable({ title, max = 0 }) {
  const location = useLocation();
  let navigate = useNavigate();
  const size = useWindowSize();
  const { loading, getActivities, activities, user, getActivityById } =
    useContext(UserContext);

  useEffect(() => {
    getActivities();
  }, []);

  const columns = useMemo(() => {
    if (user.data.is_admin) {
      return [
        {
          Header: 'Type',
          accessor: 'activity_type_name',
        },
        {
          Header: 'Activity',
          accessor: 'name',
        },
        {
          Header: 'Points',
          accessor: 'credits',
          isNumeric: true,
          fontWeight: 'bold',
        },
        {
          Header: '',
          accessor: 'id',
          isNumeric: true,
          Cell: ({ cell: { value }, row: { values, original } }) => (
            <IconButton
              bgColor="cyan.600"
              color="white"
              aria-label={'Edit Activity'}
              size="sm"
              ml={2}
              mr={5}
              onClick={async () => {
                await getActivityById(value);
                navigate('/activities/update/' + value);
              }}
              icon={<EditIcon />}
            />
          ),
        },
      ];
    } else {
      return [
        {
          Header: 'Type',
          accessor: 'activity_type_name',
        },
        {
          Header: 'Activity',
          accessor: 'name',
        },
        {
          Header: 'Points',
          accessor: 'credits',
          isNumeric: true,
          fontWeight: 'bold',
        },
        {
          Header: '',
          accessor: 'id',
          isNumeric: true,
          Cell: ({ cell: { value }, row: { values, original } }) => (
            <RepeatActivityButton
              data={{
                ...original,
                activity_id: original.id,
              }}
              label="Log this activity"
              icon={<AddIcon />}
            />
          ),
        },
      ];
    }
  }, []);

  const getTableButtons = () => {
    return (
      <>
        <Tooltip label={'Refresh activity'}>
          <IconButton
            bgColor="cyan.600"
            color="white"
            aria-label={'Refresh activity'}
            size="sm"
            ml={2}
            mr={5}
            onClick={() => {
              getActivities();
            }}
            icon={<MdRefresh />}
          />
        </Tooltip>
        <Tooltip label={'Create new activity'}>
          <IconButton
            bgColor="cyan.600"
            color="white"
            aria-label={'Create new activity'}
            size="sm"
            ml={2}
            mr={5}
            onClick={() => {
              navigate('/activities/create');
            }}
            icon={<AddIcon />}
          />
        </Tooltip>
      </>
    );
  };

  return (
    <>
      {loading ? (
        <TableLoadingSkeleton />
      ) : (
        <CardBox>
          <DataTable
            size={size[0] <= 768 ? 'sm' : 'md'}
            _columns={columns}
            _data={max === 0 ? activities : activities.slice(0, max)}
            filters={['activity_type_name', 'name', 'credits']}
            button={getTableButtons()}
            title={title}
          />
        </CardBox>
      )}
    </>
  );
}

export default ActivitiesListTable;
