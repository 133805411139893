import { useContext, useEffect, useState } from 'react';
import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import { useNavigate, useSearchParams } from 'react-router-dom';
import InputText from '../formik/InputText';
import InputSelect from '../formik/InputSelect';
import SubmitButton from '../formik/SubmitButton';
import CardBox from '../common/CardBox';
import { getAuth } from 'firebase/auth';
import { UserContext } from '../../providers/UserProvider';
import { AppContext } from '../../providers/AppProvider';

function InviteMemberSignUpForm() {
  const { signUp } = useContext(UserContext);
  const { clubs, branches, states } = useContext(AppContext);
  const auth = getAuth();
  let navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [years, setYears] = useState([]);

  const generateArrayOfYears = async () => {
    var max = new Date().getFullYear();
    var min = max - 100;
    var years = [];

    for (var i = max; i >= min; i--) {
      if (i <= 2010) {
        years.push(i);
      }
    }

    return years;
  };

  const userTypes = [
    {
      id: 1,
      name: 'Member',
    },
    {
      id: 2,
      name: 'Club Admin',
    },
    {
      id: 3,
      name: 'Branch Admin',
    },
    {
      id: 4,
      name: 'State Admin',
    },
    {
      id: 5,
      name: 'Super Admin',
    },
  ];

  useEffect(() => {
    generateArrayOfYears().then(data => {
      setYears(data);
      console.log(data);
    });
  }, []);

  return (
    <CardBox bg="gray.50" borderRadius="2xl" p={5}>
      {searchParams && (
        <Formik
          initialValues={{
            club_id: searchParams.get('club_id')
              ? searchParams.get('club_id')
              : 1,
            account_type_id: searchParams.get('account_type_id'),
            club_state_id: searchParams.get('club_state_id'),
            club_branch_id: searchParams.get('club_branch_id'),
            name: searchParams.get('name'),
            email: searchParams.get('email'),
            year_of_birth: searchParams.get('year_of_birth'),
            from_invite: true,
            invite_token: searchParams.get('invite_token'),
            password: '',
            showPassword: false,
          }}
          validationSchema={SignupSchema}
          onSubmit={(values, actions) => {
            values.club_id = parseInt(values.club_id);
            values.year_of_birth = values.year_of_birth;
            var data = {
              data: values,
            };
            signUp(data);

            setTimeout(() => {
              console.log(values);
              actions.setSubmitting(false);
              //  actions.resetForm();
            }, 1000);
          }}
        >
          {({ isSubmitting, values }) => (
            <Form>
              <InputText name="name" />
              <InputText name="email" type="email" disabled={true} />
              <InputSelect
                name="account_type_id"
                title="Member Type"
                options={userTypes}
                disabled={true}
              />

              {values.account_type_id == 1 || values.account_type_id == 2 ? (
                <InputSelect
                  name="club_id"
                  title="Club"
                  options={clubs}
                  disabled={true}
                />
              ) : null}

              {values.account_type_id == 3 ? (
                <InputSelect
                  name="club_branch_id"
                  title="Branch"
                  options={branches}
                  disabled={true}
                />
              ) : null}

              {values.account_type_id == 4 ? (
                <InputSelect
                  name="club_state_id"
                  title="State"
                  options={states}
                  disabled={true}
                />
              ) : null}
              {years && (
                <InputSelect
                  name="year_of_birth"
                  title="Year of birth"
                  options={years}
                />
              )}
              <InputText name="password" type="password" />
              <SubmitButton isSubmitting={isSubmitting} />
            </Form>
          )}
        </Formik>
      )}
    </CardBox>
  );
}

const SignupSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
    .required('Required'),
  club_id: Yup.number('Required').required('Required'),
  year_of_birth: Yup.number().required('Required'),
  password: Yup.string().min(3, 'Password is too short').required('Required'),
  email: Yup.string().email('Invalid email').required('Required'),
});

export default InviteMemberSignUpForm;
