import React from 'react';
import { Box, SimpleGrid } from '@chakra-ui/react';
import LogNewActivity from '../../components/forms/LogNewActivity';

function LogActivityPage() {
  return (
    <Box>
      <SimpleGrid columns={1} spacing="24px">
        <LogNewActivity />
      </SimpleGrid>
    </Box>
  );
}

export default LogActivityPage;
