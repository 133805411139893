import { useContext, useEffect, useState } from 'react';
import {
  Box,
  SimpleGrid,
  FormControl,
  FormLabel,
  Switch,
} from '@chakra-ui/react';
import TableLoadingSkeleton from '../../components/loading/TableLoadingSkeleton';
import CardBox from '../../components/common/CardBox';
import { UserContext } from '../../providers/UserProvider';

function ClubSettings() {
  const { user, loading, getUserInfo, toggleAutoApprove } =
    useContext(UserContext);

  useEffect(() => {
    getUserInfo();
  }, []);

  if (loading === true) {
    return (
      <CardBox>
        <TableLoadingSkeleton />{' '}
      </CardBox>
    );
  }

  return (
    <CardBox>
      <SimpleGrid columns={1} spacing="24px">
        <FormControl display="flex" alignItems="center">
          <FormLabel htmlFor="auto_approve" mb="0">
            Enable Auto Approve Activities?
          </FormLabel>
          <Switch
            id="auto_approve"
            defaultChecked={user.data.with_auto_approve_option}
            onChange={e => {
              toggleAutoApprove(e.target.checked);
              console.log('e', e.target.checked);
            }}
          />
        </FormControl>
      </SimpleGrid>
    </CardBox>
  );
}

export default ClubSettings;
