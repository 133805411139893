import React, { useState, useEffect } from 'react';
import { useLocation, Link } from 'react-router-dom';
import { Box, Heading, Text } from '@chakra-ui/react';

function Breadcrumbs({ title }) {
  const location = useLocation();
  const [breadCrumbData, setBreadCrumbData] = useState('');
  const [allBreadCrumbData, setAllBreadCrumbData] = useState([]);

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  useEffect(() => {
    console.log(location.pathname);

    var _routes = location.pathname.split('/');
    setAllBreadCrumbData(_routes);
    var _name = capitalizeFirstLetter(_routes[_routes.length - 1]);
    setBreadCrumbData(_name);
    console.log(_routes);
  }, [location.pathname]);

  return (
    <Box ml={3} mt={3}>
      <Text as="span" fontWeight={500} color="gray.600">
        {allBreadCrumbData.map((route, index) => {
          console.log('route', route);
          if (route == '') {
            return (
              <React.Fragment key={index}>
                <Link to="/dashboard" style={{ cursor: 'pointer' }}>
                  <Text as="span" fontSize={'sm'} color={'blue.500'}>
                    Dashboard&nbsp;
                  </Text>
                </Link>
              </React.Fragment>
            );
          } else if (route != 'dashboard') {
            return (
              <React.Fragment key={index}>
                <Text as="span" fontSize={'sm'} color={'gray.800'}>
                  / {capitalizeFirstLetter(route)}
                </Text>
              </React.Fragment>
            );
          }
        })}
      </Text>
    </Box>
  );
}

export default Breadcrumbs;
