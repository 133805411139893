import React from 'react';
import { Box, SimpleGrid, Stack } from '@chakra-ui/react';
import PreloadedPointsTable from '../../components/preload/PreloadedPointsTable';

function PreloadedPointsPage() {
  return (
    <Box>
      <SimpleGrid columns={1} spacing="24px">
        <PreloadedPointsTable title="Preloaded Points" />
      </SimpleGrid>
    </Box>
  );
}

export default PreloadedPointsPage;
