import { useContext, useEffect } from 'react';
import CardBox from '../common/CardBox';
import { UserContext } from '../../providers/UserProvider';
import DeletedActivitiesTable from './DeletedActivitiesTable';

function DeletedActivities() {
  const { getDeletedActivities } = useContext(UserContext);

  useEffect(() => {
    getDeletedActivities();
  }, []);

  return (
    <CardBox>
      <DeletedActivitiesTable title="Deleted Activities" />
    </CardBox>
  );
}

export default DeletedActivities;
