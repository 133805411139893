import { Box, SimpleGrid } from '@chakra-ui/react';
import AllLeaderboards from '../../components/leaderboard/AllLeaderboards';

function LeaderboardPage() {
  return (
    <Box>
      <SimpleGrid columns={1} spacing="24px">
        <AllLeaderboards />
      </SimpleGrid>
    </Box>
  );
}

export default LeaderboardPage;
