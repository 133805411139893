import React, { useContext } from 'react';
import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import { useNavigate } from 'react-router-dom';
import InputText from '../formik/InputText';
import InputSelect from '../formik/InputSelect';
import CustomSelect from '../formik/CustomSelect';
import SubmitButton from '../formik/SubmitButton';
import CardBox from '../common/CardBox';
import { UserContext } from '../../providers/UserProvider';

function ChangePasswordForm() {
  let navigate = useNavigate();
  const { changePassword } = useContext(UserContext);

  return (
    <CardBox bg="gray.50" borderRadius="2xl" p={5}>
      <Formik
        initialValues={{
          old_password: '',
          new_password: '',
        }}
        validationSchema={SignupSchema}
        onSubmit={(values, actions) => {
          console.log(values);
          changePassword(values);
          setTimeout(() => {
            actions.setSubmitting(false);
            actions.resetForm();
          }, 1000);
        }}
      >
        {({ isSubmitting }) => (
          <Form>
            <InputText
              name="old_password"
              title="Old Password"
              type="password"
            />
            <InputText
              name="new_password"
              title="New Password"
              type="password"
            />
            <SubmitButton isSubmitting={isSubmitting} title="Update Password" />
          </Form>
        )}
      </Formik>
    </CardBox>
  );
}

const SignupSchema = Yup.object().shape({
  old_password: Yup.string().required('Required'),
  new_password: Yup.string()
    .min(3, 'Password is too short')
    .required('Required'),
});

export default ChangePasswordForm;
