import React from 'react';
import { Box, SimpleGrid } from '@chakra-ui/react';
import AllActivities from '../../components/activity/AllActivities';

function MyActivitiesHistoryPage() {
  return (
    <Box>
      <SimpleGrid columns={1} spacing="24px">
        <AllActivities />
      </SimpleGrid>
    </Box>
  );
}

export default MyActivitiesHistoryPage;
