import { Skeleton, Stack } from '@chakra-ui/react';

function TableLoadingSkeleton({ height = '30px' }) {
  return (
    <Stack>
      <Skeleton height={height} />
      <Skeleton height={height} />
      <Skeleton height={height} />
    </Stack>
  );
}

export default TableLoadingSkeleton;
