import { useContext } from 'react';
import {
  Image,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuItemOption,
  MenuGroup,
  MenuOptionGroup,
  MenuDivider,
  Tooltip,
  Box,
  Button,
  Divider,
  Text,
} from '@chakra-ui/react';
import { getAuth } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import { ChevronDownIcon } from '@chakra-ui/icons';
import { RiDoorOpenFill } from 'react-icons/ri';
import { FaLock, FaUserAlt } from 'react-icons/fa';
import { UserContext } from '../../../providers/UserProvider';
import IMAGE_ASSETS from '../../../assets/image-list';

function SideMenu() {
  const auth = getAuth();
  let navigate = useNavigate();
  const { logOut, user } = useContext(UserContext);

  return (
    <Box>
      <Menu>
        <Tooltip label="Activities Menu">
          <MenuButton
            w="100%"
            as={Button}
            aria-label="User"
            rightIcon={<ChevronDownIcon />}
            textAlign={'left'}
            disabled={
              !user.data.is_admin && !user.data.is_approved ? true : false
            }
          >
            Activities
          </MenuButton>
        </Tooltip>
        <MenuList>
          {user.data.is_admin ? (
            <MenuItem
              onClick={() => {
                navigate('/club/activities');
              }}
            >
              Club Activities
            </MenuItem>
          ) : null}
          {user.data.is_admin === true ? (
            <MenuItem
              onClick={() => {
                navigate('/activities/add-new');
              }}
            >
              Add Activity
            </MenuItem>
          ) : null}
          <MenuItem
            onClick={() => {
              navigate('/activities/list');
            }}
          >
            Activity List
          </MenuItem>
          <MenuItem
            onClick={() => {
              navigate('/activities/deleted');
            }}
          >
            Deleted Activities
          </MenuItem>
          {user.data.is_admin === false ? (
            <MenuItem
              onClick={() => {
                navigate('/activities/history');
              }}
            >
              My Activities
            </MenuItem>
          ) : null}
          {user.data.is_admin === true ? (
            <>
              <MenuItem
                onClick={() => {
                  navigate('/points/preloaded');
                }}
              >
                Preloaded Points
              </MenuItem>
              <MenuItem
                onClick={() => {
                  navigate('/points/preload');
                }}
              >
                Preload Points
              </MenuItem>
            </>
          ) : null}
        </MenuList>
      </Menu>
      <Divider my={2} />
      <Menu>
        <Tooltip label="Club Menu">
          <MenuButton
            w="100%"
            as={Button}
            aria-label="User"
            rightIcon={<ChevronDownIcon />}
            textAlign={'left'}
            disabled={
              !user.data.is_admin && !user.data.is_approved ? true : false
            }
          >
            Club
          </MenuButton>
        </Tooltip>
        <MenuList>
          {user.data.is_admin ? (
            <MenuItem
              onClick={() => {
                navigate('/club/activities');
              }}
            >
              Club Activities
            </MenuItem>
          ) : null}
          <MenuItem
            onClick={() => {
              navigate('/club/members');
            }}
          >
            Club Members
          </MenuItem>
          <MenuItem
            onClick={() => {
              navigate('/club/settings');
            }}
          >
            Club Settings
          </MenuItem>
          <MenuItem
            onClick={() => {
              navigate('/club/members/deleted');
            }}
          >
            Deleted Members
          </MenuItem>
          {user.data.is_admin ? (
            <MenuItem
              onClick={() => {
                navigate('/club/invite');
              }}
            >
              Invite Member
            </MenuItem>
          ) : null}
        </MenuList>
      </Menu>
      <Divider my={2} />
      <Menu>
        <MenuButton
          w="100%"
          as={Button}
          aria-label="User"
          textAlign={'left'}
          onClick={() => {
            navigate('/leaderboards');
          }}
          disabled={
            !user.data.is_admin && !user.data.is_approved ? true : false
          }
        >
          Leaderboard
        </MenuButton>
      </Menu>
      <Divider my={2} />
    </Box>
  );
}
export default SideMenu;
