const IMAGE_ASSETS = {
  src: {
    avatarDefault: '/webp/avatardefault.webp',
    facebookIcon: '/webp/facebook.webp',
    googleIcon: '/webp/google.webp',
    clubs: '/webp/clubs.webp',
    logo: '/webp/SLSA Logo.png',
  },
  caps: [
    '/webp/SLSA Logo.png',
    'https://firebasestorage.googleapis.com/v0/b/quote-shark.appspot.com/o/clubs%2FMERMAID BEACH.webp?alt=media',
    'https://firebasestorage.googleapis.com/v0/b/quote-shark.appspot.com/o/clubs%2FMERMAID BEACH.webp?alt=media',
    'https://firebasestorage.googleapis.com/v0/b/quote-shark.appspot.com/o/clubs%2FNORTH QUEENSLAND.webp?alt=media',
    'https://firebasestorage.googleapis.com/v0/b/quote-shark.appspot.com/o/clubs%2FPORT DOUGLAS.webp?alt=media',
    'https://firebasestorage.googleapis.com/v0/b/quote-shark.appspot.com/o/clubs%2FELLIS BEACH.webp?alt=media',
    'https://firebasestorage.googleapis.com/v0/b/quote-shark.appspot.com/o/clubs%2FCAIRNS.webp?alt=media',
    'https://firebasestorage.googleapis.com/v0/b/quote-shark.appspot.com/o/clubs%2FETTY BAY.webp?alt=media',
    'https://firebasestorage.googleapis.com/v0/b/quote-shark.appspot.com/o/clubs%2FMISSION BEACH.webp?alt=media',
    'https://firebasestorage.googleapis.com/v0/b/quote-shark.appspot.com/o/clubs%2FFORREST BEACH.webp?alt=media',
    'https://firebasestorage.googleapis.com/v0/b/quote-shark.appspot.com/o/clubs%2FARCADIAN.webp?alt=media',
    'https://firebasestorage.googleapis.com/v0/b/quote-shark.appspot.com/o/clubs%2FPICNIC BAY.webp?alt=media',
    'https://firebasestorage.googleapis.com/v0/b/quote-shark.appspot.com/o/clubs%2FAYR.webp?alt=media',
    'https://firebasestorage.googleapis.com/v0/b/quote-shark.appspot.com/o/clubs%2FBOWEN.webp?alt=media',
    'https://firebasestorage.googleapis.com/v0/b/quote-shark.appspot.com/o/clubs%2FEIMEO.webp?alt=media',
    'https://firebasestorage.googleapis.com/v0/b/quote-shark.appspot.com/o/clubs%2FMACKAY.webp?alt=media',
    'https://firebasestorage.googleapis.com/v0/b/quote-shark.appspot.com/o/clubs%2FSARINA.webp?alt=media',
    'https://firebasestorage.googleapis.com/v0/b/quote-shark.appspot.com/o/clubs%2FYEPPOON.webp?alt=media',
    'https://firebasestorage.googleapis.com/v0/b/quote-shark.appspot.com/o/clubs%2FEMU PARK.webp?alt=media',
    'https://firebasestorage.googleapis.com/v0/b/quote-shark.appspot.com/o/clubs%2FTANNUM SANDS.webp?alt=media',
    'https://firebasestorage.googleapis.com/v0/b/quote-shark.appspot.com/o/clubs%2FAGNES WATER.webp?alt=media',
    'https://firebasestorage.googleapis.com/v0/b/quote-shark.appspot.com/o/clubs%2FMOORE PARK.webp?alt=media',
    'https://firebasestorage.googleapis.com/v0/b/quote-shark.appspot.com/o/clubs%2FBUNDABERG.webp?alt=media',
    'https://firebasestorage.googleapis.com/v0/b/quote-shark.appspot.com/o/clubs%2FELLIOTT HEADS.webp?alt=media',
    'https://firebasestorage.googleapis.com/v0/b/quote-shark.appspot.com/o/clubs%2FHERVEY BAY.webp?alt=media',
    'https://firebasestorage.googleapis.com/v0/b/quote-shark.appspot.com/o/clubs%2FRAINBOW BEACH.webp?alt=media',
    'https://firebasestorage.googleapis.com/v0/b/quote-shark.appspot.com/o/clubs%2FNOOSA HEADS.webp?alt=media',
    'https://firebasestorage.googleapis.com/v0/b/quote-shark.appspot.com/o/clubs%2FSUNSHINE BEACH.webp?alt=media',
    'https://firebasestorage.googleapis.com/v0/b/quote-shark.appspot.com/o/clubs%2FPEREGIAN BEACH.webp?alt=media',
    'https://firebasestorage.googleapis.com/v0/b/quote-shark.appspot.com/o/clubs%2FCOOLUM BEACH.webp?alt=media',
    'https://firebasestorage.googleapis.com/v0/b/quote-shark.appspot.com/o/clubs%2FMARCOOLA.webp?alt=media',
    'https://firebasestorage.googleapis.com/v0/b/quote-shark.appspot.com/o/clubs%2FMUDJIMBA.webp?alt=media',
    'https://firebasestorage.googleapis.com/v0/b/quote-shark.appspot.com/o/clubs%2FMAROOCHYDORE.webp?alt=media',
    'https://firebasestorage.googleapis.com/v0/b/quote-shark.appspot.com/o/clubs%2FALEXANDRA HEADLAND.webp?alt=media',
    'https://firebasestorage.googleapis.com/v0/b/quote-shark.appspot.com/o/clubs%2FMOOLOOLABA.webp?alt=media',
    'https://firebasestorage.googleapis.com/v0/b/quote-shark.appspot.com/o/clubs%2FKAWANA WATERS.webp?alt=media',
    'https://firebasestorage.googleapis.com/v0/b/quote-shark.appspot.com/o/clubs%2FDICKY BEACH.webp?alt=media',
    'https://firebasestorage.googleapis.com/v0/b/quote-shark.appspot.com/o/clubs%2FMET. CALOUNDRA.webp?alt=media',
    'https://firebasestorage.googleapis.com/v0/b/quote-shark.appspot.com/o/clubs%2FBRIBIE ISLAND.webp?alt=media',
    'https://firebasestorage.googleapis.com/v0/b/quote-shark.appspot.com/o/clubs%2FREDCLIFFE PENINSULA.webp?alt=media',
    'https://firebasestorage.googleapis.com/v0/b/quote-shark.appspot.com/o/clubs%2FPOINT LOOKOUT.webp?alt=media',
    'https://firebasestorage.googleapis.com/v0/b/quote-shark.appspot.com/o/clubs%2FCOOCHIEMUDLO ISLAND.webp?alt=media',
    'https://firebasestorage.googleapis.com/v0/b/quote-shark.appspot.com/o/clubs%2FSOUTHPORT.webp?alt=media',
    'https://firebasestorage.googleapis.com/v0/b/quote-shark.appspot.com/o/clubs%2FSURFERS PARADISE.webp?alt=media',
    'https://firebasestorage.googleapis.com/v0/b/quote-shark.appspot.com/o/clubs%2FNORTHCLIFFE.webp?alt=media',
    'https://firebasestorage.googleapis.com/v0/b/quote-shark.appspot.com/o/clubs%2FBROADBEACH.webp?alt=media',
    'https://firebasestorage.googleapis.com/v0/b/quote-shark.appspot.com/o/clubs%2FKURRAWA.webp?alt=media',
    'https://firebasestorage.googleapis.com/v0/b/quote-shark.appspot.com/o/clubs%2FNOBBYS BEACH.webp?alt=media',
    'https://firebasestorage.googleapis.com/v0/b/quote-shark.appspot.com/o/clubs%2FMIAMI BEACH.webp?alt=media',
    'https://firebasestorage.googleapis.com/v0/b/quote-shark.appspot.com/o/clubs%2FNORTH BURLEIGH.webp?alt=media',
    'https://firebasestorage.googleapis.com/v0/b/quote-shark.appspot.com/o/clubs%2FBURLEIGH HEADS/MOWBRAY PARK.webp?alt=media',
    'https://firebasestorage.googleapis.com/v0/b/quote-shark.appspot.com/o/clubs%2FTALLEBUDGERA.webp?alt=media',
    'https://firebasestorage.googleapis.com/v0/b/quote-shark.appspot.com/o/clubs%2FPACIFIC.webp?alt=media',
    'https://firebasestorage.googleapis.com/v0/b/quote-shark.appspot.com/o/clubs%2FPALM BEACH.webp?alt=media',
    'https://firebasestorage.googleapis.com/v0/b/quote-shark.appspot.com/o/clubs%2FCURRUMBIN.webp?alt=media',
    'https://firebasestorage.googleapis.com/v0/b/quote-shark.appspot.com/o/clubs%2FTUGUN.webp?alt=media',
    'https://firebasestorage.googleapis.com/v0/b/quote-shark.appspot.com/o/clubs%2FBILINGA.webp?alt=media',
    'https://firebasestorage.googleapis.com/v0/b/quote-shark.appspot.com/o/clubs%2FNORTH KIRRA.webp?alt=media',
    'https://firebasestorage.googleapis.com/v0/b/quote-shark.appspot.com/o/clubs%2FKIRRA.webp?alt=media',
    'https://firebasestorage.googleapis.com/v0/b/quote-shark.appspot.com/o/clubs%2FCOOLANGATTA.webp?alt=media',
    'https://firebasestorage.googleapis.com/v0/b/quote-shark.appspot.com/o/clubs%2FTWEED HEADS & COOLANGATTA.webp?alt=media',
    'https://firebasestorage.googleapis.com/v0/b/quote-shark.appspot.com/o/clubs%2FRAINBOW BAY.webp?alt=media',
    'https://firebasestorage.googleapis.com/v0/b/quote-shark.appspot.com/o/clubs%2FSOUTH BARRIER BRANCH.webp?alt=media',
    'https://firebasestorage.googleapis.com/v0/b/quote-shark.appspot.com/o/clubs%2FNTH BARRIER BRANCH.webp?alt=media',
    'https://firebasestorage.googleapis.com/v0/b/quote-shark.appspot.com/o/clubs%2FWIDE BAY CAPRICORN BRANCH.webp?alt=media',
    'https://firebasestorage.googleapis.com/v0/b/quote-shark.appspot.com/o/clubs%2FSUNSHINE COAST BRANCH.webp?alt=media',
    'https://firebasestorage.googleapis.com/v0/b/quote-shark.appspot.com/o/clubs%2FPOINT DANGER BRANCH.webp?alt=media',
    'https://firebasestorage.googleapis.com/v0/b/quote-shark.appspot.com/o/clubs%2FTEA%20GARDENS%20HAWKS%20NEST%20SLSC.png?alt=media',
    'https://firebasestorage.googleapis.com/v0/b/quote-shark.appspot.com/o/clubs%2FFINGAL%20BEACH%20SLSC.png?alt=media',
    'https://firebasestorage.googleapis.com/v0/b/quote-shark.appspot.com/o/clubs%2FBIRUBI%20POIN%20SLSC.png?alt=media',
    'https://firebasestorage.googleapis.com/v0/b/quote-shark.appspot.com/o/clubs%2FSTOCKTON%20SLSC.png?alt=media',
    'https://firebasestorage.googleapis.com/v0/b/quote-shark.appspot.com/o/clubs%2FNOBBYS%20(NSW)%20SLSC.png?alt=media',
    'https://firebasestorage.googleapis.com/v0/b/quote-shark.appspot.com/o/clubs%2FNEWCASTLE%20SLSC.png?alt=media',
    'https://firebasestorage.googleapis.com/v0/b/quote-shark.appspot.com/o/clubs%2FCOOKS%20HILL%20SLSC.png?alt=media',
    'https://firebasestorage.googleapis.com/v0/b/quote-shark.appspot.com/o/clubs%2FDIXON%20PARK%20SLSC.png?alt=media',
    'https://firebasestorage.googleapis.com/v0/b/quote-shark.appspot.com/o/clubs%2FMEREWETHER%20SLSC.png?alt=media',
    'https://firebasestorage.googleapis.com/v0/b/quote-shark.appspot.com/o/clubs%2FREDHEAD%20SLSC.png?alt=media',
    'https://firebasestorage.googleapis.com/v0/b/quote-shark.appspot.com/o/clubs%2FSWANSEA%20BELMONT%20SLSC.png?alt=media',
    'https://firebasestorage.googleapis.com/v0/b/quote-shark.appspot.com/o/clubs%2FCAVES%20BEACH%20SLSC.png?alt=media',
    'https://firebasestorage.googleapis.com/v0/b/quote-shark.appspot.com/o/clubs%2FCATHERINE%20HILL%20BAY.png?alt=media',
    'https://firebasestorage.googleapis.com/v0/b/quote-shark.appspot.com/o/clubs%2Fevans%20head.png?alt=media',
  ],
};
export default IMAGE_ASSETS;
