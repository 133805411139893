import React from 'react';
import { Box, SimpleGrid } from '@chakra-ui/react';
import InviteMemberForm from '../../components/invite/InviteMemberForm';

function InviteMemberPage() {
  return (
    <Box>
      <SimpleGrid columns={1} spacing="24px">
        <InviteMemberForm />
      </SimpleGrid>
    </Box>
  );
}

export default InviteMemberPage;
