import { useContext } from 'react';
import { Link } from 'react-router-dom';
import { IconButton, Tooltip } from '@chakra-ui/react';
import { AddIcon } from '@chakra-ui/icons';
import { UserContext } from '../../providers/UserProvider';
import { useNavigate } from 'react-router-dom';

function ActivityButton() {
  const { user } = useContext(UserContext);
  const navigate = useNavigate();

  return (
    <Tooltip label={user.data.is_admin ? 'Add activity' : 'Log Activity'}>
      <IconButton
        bgColor="cyan.600"
        color="white"
        aria-label={user.data.is_admin ? 'Add activity' : 'Log Activity'}
        size="sm"
        disabled={!user.data.is_admin && !user.data.is_approved ? true : false}
        onClick={() => {
          if (user.data.is_admin) {
            navigate('/activities/add-new');
          } else {
            navigate('/activities/log-new');
          }
        }}
        icon={<AddIcon />}
      />
    </Tooltip>
  );
}

export default ActivityButton;
