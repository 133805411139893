import { Field } from 'formik';
import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  Select,
} from '@chakra-ui/react';

function InputSelect({ name, title = '', options = [], disabled = false }) {
  return (
    <Field name={name}>
      {({ field, form }) => (
        <FormControl isInvalid={form.errors[name] && form.touched[name]}>
          <FormLabel htmlFor={name} mt={3}>
            {title !== ''
              ? title.charAt(0).toUpperCase() + title.slice(1)
              : name.charAt(0).toUpperCase() + name.slice(1)}
          </FormLabel>

          <Select
            {...field}
            id={name}
            placeholder={`${title !== '' ? title : 'Select a ' + name}`}
            disabled={disabled}
          >
            {options.map((value, key) => {
              if (typeof value === 'string') {
                return (
                  <option key={key} value={key + 1}>
                    {value}
                  </option>
                );
              } else if (typeof value === 'number') {
                return (
                  <option key={key} value={value}>
                    {value}
                  </option>
                );
              } else {
                return (
                  <option key={value.id} value={value.id}>
                    {value.name}
                  </option>
                );
              }
            })}
          </Select>

          <FormErrorMessage>{form.errors[name]}</FormErrorMessage>
        </FormControl>
      )}
    </Field>
  );
}

export default InputSelect;
