import { useContext } from 'react';
import { Link } from 'react-router-dom';
import { IconButton, Tooltip } from '@chakra-ui/react';
import { EditIcon } from '@chakra-ui/icons';
import { UserContext } from '../../providers/UserProvider';
import { useNavigate } from 'react-router-dom';
import { BsArrowRepeat } from 'react-icons/bs';

function RepeatActivityButton({
  data,
  label = '',
  icon = null,
  disabled = false,
}) {
  const navigate = useNavigate();

  return (
    <Tooltip label={label === '' ? 'Repeat Activity' : label}>
      <IconButton
        bgColor="cyan.600"
        color="white"
        size="sm"
        onClick={() => {
          navigate('/activities/log-new', {
            state: {
              data: data,
            },
          });
        }}
        icon={icon ? icon : <BsArrowRepeat />}
        disabled={disabled}
      />
    </Tooltip>
  );
}

export default RepeatActivityButton;
