import React from 'react';
import { Box } from '@chakra-ui/react';
import NotificationsCard from '../../components/user/NotificationsCard';

function NotificationsPage() {
  return (
    <Box>
      <NotificationsCard />
    </Box>
  );
}

export default NotificationsPage;
