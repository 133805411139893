import React from 'react';
import { Box, SimpleGrid } from '@chakra-ui/react';
import PreloadPointsForm from '../../components/forms/PreloadPointsForm';

function PreloadPoints() {
  return (
    <Box>
      <SimpleGrid columns={1} spacing="24px">
        <PreloadPointsForm />
      </SimpleGrid>
    </Box>
  );
}

export default PreloadPoints;
