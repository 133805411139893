import React, { useContext, useEffect, useState } from 'react';
import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import { useNavigate } from 'react-router-dom';
import InputText from '../formik/InputText';
import InputSelect from '../formik/InputSelect';
import { Select } from '@chakra-ui/react';
import CustomSelect from '../formik/CustomSelect';
import SubmitButton from '../formik/SubmitButton';
import CardBox from '../common/CardBox';
import { UserContext } from '../../providers/UserProvider';

function CreateActivityForm() {
  let navigate = useNavigate();
  const {
    getAccountTypes,
    getBranches,
    getStates,
    getClubs,
    clubs,
    branches,
    activityTypes,
    getActivityTypes,
    states,
    createActivity,
  } = useContext(UserContext);

  const levelOptions = [
    {
      id: 1,
      name: 'Club Level',
    },
    {
      id: 3,
      name: 'Branch Level',
    },
    {
      id: 4,
      name: 'State Level',
    },
  ];

  useEffect(() => {
    if (activityTypes.length === 0) {
      getActivityTypes();
    }
    getAccountTypes();
    getClubs();
    getBranches();
    getStates();
  }, []);

  return (
    <CardBox bg="gray.50" borderRadius="2xl" p={5}>
      <Formik
        initialValues={{
          name: '',
          activity_type_id: '',
          credits: '',
          club_id: null,
          club_branch_id: null,
          club_state_id: null,
          level: null,
        }}
        validationSchema={SignupSchema}
        onSubmit={(values, actions) => {
          let data = {
            name: values.name,
            credits: values.credits,
            activity_type_id: parseInt(values.activity_type_id),
            club_id: parseInt(values.club_id),
            club_branch_id: parseInt(values.club_branch_id),
            club_state_id: parseInt(values.club_state_id),
          };
          console.log(data);
          createActivity(data);
          setTimeout(() => {
            actions.setSubmitting(false);
            actions.resetForm();
          }, 1000);
        }}
      >
        {({ isSubmitting, values }) => (
          <Form>
            <InputSelect
              name="activity_type_id"
              title="Activity Type"
              options={activityTypes}
            />

            <InputText name="name" />
            <InputText name="credits" type="number" />

            <InputSelect
              name="level"
              title="Availability Level"
              options={levelOptions}
            />

            {values.level === '1' || values.level === '2' ? (
              <InputSelect name="club_id" title="Club" options={clubs} />
            ) : null}

            {values.level === '3' ? (
              <InputSelect
                name="club_branch_id"
                title="Branch"
                options={branches}
              />
            ) : null}

            {values.level === '4' ? (
              <InputSelect
                name="club_state_id"
                title="State"
                options={states}
              />
            ) : null}
            <SubmitButton isSubmitting={isSubmitting} />
          </Form>
        )}
      </Formik>
    </CardBox>
  );
}

const SignupSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
    .required('Required'),
  credits: Yup.number().required('Required'),
  level: Yup.number().required('Required'),
  activity_type_id: Yup.string().required('Required'),
});

export default CreateActivityForm;
