import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import {
  IconButton,
  Avatar,
  Box,
  CloseButton,
  Flex,
  HStack,
  VStack,
  Icon,
  useColorModeValue,
  Link,
  Drawer,
  DrawerContent,
  Text,
  useDisclosure,
  BoxProps,
  FlexProps,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Progress,
  Spacer,
  Badge,
  Stack,
  SimpleGrid,
} from '@chakra-ui/react';
import {
  FiHome,
  FiTrendingUp,
  FiCompass,
  FiStar,
  FiSettings,
  FiMenu,
  FiBell,
  FiChevronDown,
  FiUser,
  FiImage,
  FiDatabase,
} from 'react-icons/fi';
import IMAGE_ASSETS from '../../../assets/image-list';
import { UserContext } from '../../../providers/UserProvider';
import NotificationsMenu from '../Menu/NotificationsMenu';
import CardBox from '../../common/CardBox';
import SideMenu from '../Menu/SideMenu';
import BranchStateSuperAdminMenu from '../Menu/BranchStateSuperAdminMenu';
import UserStats from '../../user/UserStats';
import Breadcrumbs from '../../common/Breadcrumbs';

const LinkItems = [
  { name: 'Dashboard', icon: FiHome, href: '#' },
  { name: 'Users', icon: FiUser, href: '#' },
  { name: 'Location History', icon: FiCompass, href: '#' },
  { name: 'Images', icon: FiImage, href: '#' },
  { name: 'User Logs', icon: FiDatabase, href: '#' },
  { name: 'Settings', icon: FiSettings, href: '#' },
];

export default function DashboardLayout({ children }) {
  const { user, logOut, getUserInfo, userTypes } = useContext(UserContext);
  let navigate = useNavigate();
  let location = useLocation();
  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    if (!user) {
      navigate('/sign-in');
    }
    getUserInfo();
  }, [location.pathname]);

  useEffect(() => {
    if (user) {
      getUserInfo();
    }
  }, []);

  return (
    <Box w="full" bg={useColorModeValue('gray.100', 'gray.900')}>
      {/* <SidebarContent
        onClose={() => onClose}
        display={{ base: 'none', md: 'block' }}
        user={user}
      />
      <Drawer
        autoFocus={false}
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        returnFocusOnClose={false}
        onOverlayClick={onClose}
        size="full"
      >
        <DrawerContent>
          <SidebarContent onClose={onClose} />

        </DrawerContent>
      </Drawer> */}

      {/* mobilenav */}
      {user && (
        <>
          <MobileNav
            user={user}
            navigate={navigate}
            logOut={logOut}
            onOpen={onOpen}
            userTypes={userTypes}
          />

          <Breadcrumbs />
          <Stack direction={['column', 'column', 'row']} spacing={4} p={3}>
            <Box w={{ base: 'full', md: 'full', lg: '250px' }}>
              {user.data.is_admin ? null : (
                <Box mb={2}>
                  <UserStats />
                </Box>
              )}
              <CardBox>
                {user.data.account_type_id > 2 ? (
                  <BranchStateSuperAdminMenu />
                ) : (
                  <SideMenu />
                )}
              </CardBox>
            </Box>
            <Box flex="1">{children}</Box>
          </Stack>
        </>
      )}
    </Box>
  );
}

const SidebarContent = ({ user, onClose, ...rest }) => {
  return (
    <Box
      transition="3s ease"
      bg={useColorModeValue('white', 'gray.900')}
      borderRight="1px"
      borderRightColor={useColorModeValue('gray.200', 'gray.700')}
      w={{ base: 'full', md: 60 }}
      pos="fixed"
      h="full"
      {...rest}
    >
      <Flex h="20" alignItems="center" mx="8" justifyContent="space-between">
        <Text fontSize="2xl" fontFamily="monospace" fontWeight="bold">
          BPC Admin
        </Text>
        <CloseButton display={{ base: 'flex', md: 'none' }} onClick={onClose} />
      </Flex>
      {LinkItems.map(link => (
        <NavItem key={link.name} icon={link.icon}>
          {link.name}
        </NavItem>
      ))}
    </Box>
  );
};

const NavItem = ({ icon, children, ...rest }) => {
  return (
    <Link
      href="#"
      style={{ textDecoration: 'none' }}
      _focus={{ boxShadow: 'none' }}
    >
      <Flex
        align="center"
        p="4"
        mx="4"
        borderRadius="lg"
        role="group"
        cursor="pointer"
        _hover={{
          bg: 'cyan.400',
          color: 'white',
        }}
        {...rest}
      >
        {icon && (
          <Icon
            mr="4"
            fontSize="16"
            _groupHover={{
              color: 'white',
            }}
            as={icon}
          />
        )}
        {children}
      </Flex>
    </Link>
  );
};

const MobileNav = ({ user, navigate, logOut, onOpen, userTypes, ...rest }) => {
  const getBadge = () => {
    let badge = '';

    if (user.data.account_type_id === 1 || user.data.account_type_id === 2) {
      badge = user.data.club_name;
    } else if (user.data.account_type_id === 3) {
      badge = user.data.branch_name;
    } else if (user.data.account_type_id === 4) {
      badge = user.data.state_name;
    } else if (user.data.account_type_id === 5) {
      badge = 'Super Admin';
    }

    return badge;
  };

  return (
    <Flex
      //   ml={{ base: 0, md: 60 }}
      w="full"
      px={{ base: 4, md: 4 }}
      height="20"
      alignItems="center"
      bg={useColorModeValue('white', 'gray.900')}
      borderBottomWidth="1px"
      borderBottomColor={useColorModeValue('gray.200', 'gray.700')}
      // justifyContent={{ base: 'space-between', md: 'space-between' }}
      {...rest}
    >
      <Link
        href="/dashboard"
        style={{ textDecoration: 'none' }}
        _focus={{ boxShadow: 'none' }}
      >
        <Avatar size={'md'} src={IMAGE_ASSETS.src.logo} />
      </Link>

      <Spacer />

      <HStack spacing={{ base: '0', md: '6' }}>
        <NotificationsMenu />
        <Box>
          <Flex alignItems={'center'}>
            <Menu>
              <MenuButton
                py={2}
                transition="all 0.3s"
                _focus={{ boxShadow: 'none' }}
              >
                <HStack spacing={2}>
                  {user.account_type_id > 1 ? (
                    <Avatar
                      size={'sm'}
                      src={IMAGE_ASSETS.caps[user.data.club_id]}
                    />
                  ) : (
                    <Avatar size={'sm'} src={IMAGE_ASSETS.src.avatarDefault} />
                  )}

                  <VStack
                    display={{ base: 'none', md: 'flex' }}
                    alignItems="flex-start"
                    spacing="0px"
                    ml="2"
                  >
                    <Text fontSize="sm">{user.data.name}</Text>
                    <Badge bgColor="cyan.600" color="white" borderRadius="lg">
                      {getBadge()}
                    </Badge>
                  </VStack>
                  <Box display={{ base: 'none', md: 'flex' }}>
                    <FiChevronDown />
                  </Box>
                </HStack>
              </MenuButton>
              <MenuList
                bg={useColorModeValue('white', 'gray.900')}
                borderColor={useColorModeValue('gray.200', 'gray.700')}
              >
                <MenuItem
                  onClick={() => {
                    navigate('/profile');
                  }}
                >
                  Profile
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    navigate('/change-password');
                  }}
                >
                  Change Password
                </MenuItem>
                <MenuDivider />
                <MenuItem
                  onClick={() => {
                    logOut();
                  }}
                >
                  Sign out
                </MenuItem>
              </MenuList>
            </Menu>
          </Flex>
        </Box>
        {''}
        {/* <IconButton
          display={{ base: 'flex', md: 'none' }}
          onClick={onOpen}
          variant="outline"
          aria-label="open menu"
          icon={<FiMenu />}
        /> */}
      </HStack>
    </Flex>
  );
};
