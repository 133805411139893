import React, { useContext } from 'react';
import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import { Link } from '@chakra-ui/react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import InputText from '../formik/InputText';
import SubmitButton from '../formik/SubmitButton';
import SocialLoginButtons from './SocialLoginButtons';
import CardBox from '../common/CardBox';
import { UserContext } from '../../providers/UserProvider';

function ResetPasswordForm() {
  let navigate = useNavigate();
  const { resetPassword } = useContext(UserContext);
  const [searchParams, setSearchParams] = useSearchParams();

  return (
    <CardBox>
      <Formik
        initialValues={{
          email: searchParams.get('email'),
          reset_password_token: searchParams.get('reset_password_token'),
          new_password: '',
          confirm_password: '',
          showPassword: false,
        }}
        validationSchema={SignupSchema}
        onSubmit={(values, actions) => {
          setTimeout(() => {
            console.log(values);
            actions.setSubmitting(false);
            resetPassword(values);
          }, 1500);
        }}
      >
        {({ isSubmitting }) => (
          <Form>
            <InputText name="new_password" title="Password" type="password" />
            <InputText
              name="confirm_password"
              title="Confirm Password"
              type="password"
            />
            <SubmitButton isSubmitting={isSubmitting} />
          </Form>
        )}
      </Formik>
    </CardBox>
  );
}

const SignupSchema = Yup.object().shape({
  new_password: Yup.string()
    .min(3, 'Password is too short')
    .required('Required'),
  confirm_password: Yup.string().oneOf(
    [Yup.ref('new_password'), null],
    'Passwords must match'
  ),
  email: Yup.string().email('Invalid email').required('Required'),
});

export default ResetPasswordForm;
