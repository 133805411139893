import React from 'react';
import { Box, SimpleGrid } from '@chakra-ui/react';
import AddNewActivity from '../../components/forms/AddNewActivity';

function AddActivityPage() {
  return (
    <Box>
      <SimpleGrid columns={1} spacing="24px">
        <AddNewActivity />
      </SimpleGrid>
    </Box>
  );
}

export default AddActivityPage;
