import React, { useMemo, useCallback, useEffect, useState } from 'react';
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  chakra,
  Input,
  Box,
  Text,
  TableCaption,
  InputGroup,
  InputRightElement,
  Grid,
  GridItem,
  Center,
} from '@chakra-ui/react';
import {
  TriangleDownIcon,
  TriangleUpIcon,
  Search2Icon,
} from '@chakra-ui/icons';
import { useTable, useSortBy, useGlobalFilter } from 'react-table';
import { matchSorter } from 'match-sorter';
import useWindowSize from '../../helper/useWindowSize';

export default function DataTable({
  _data,
  _columns,
  filters,
  title = '',
  button,
  tableCaption,
  size,
  searchable = true,
  sortOption = {},
}) {
  const [filter, setFilter] = useState('');
  const windowSize = useWindowSize();
  const data = useMemo(() => _data, []);

  const columns = useMemo(() => _columns, []);

  const ourGlobalFilterFunction = useCallback(
    (rows, ids, query) => {
      return matchSorter(rows, query, {
        keys: filters.map(columnName => `values.${columnName}`),
      });
    },
    [filters]
  );

  const {
    getTableProps,
    getTableBodyProps,
    setGlobalFilter,
    headerGroups,
    rows,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
      globalFilter: ourGlobalFilterFunction,
      initialState: {
        sortBy: [sortOption],
      },
    },
    useGlobalFilter,
    useSortBy
  );

  useEffect(() => {
    setGlobalFilter(filter); // Set the Global Filter to the filter prop.
  }, [filter, setGlobalFilter]);

  return (
    <Box>
      <Text as="b" fontSize="1rem" pr={1}>
        {title}
      </Text>
      <Grid templateColumns={`repeat(${_columns.length}, 1fr)`}>
        <GridItem colSpan={_columns.length - 2}>
          <div style={{ display: searchable ? 'block' : 'none' }}>
            <InputGroup w={windowSize[0] <= 768 ? '100%' : '50%'}>
              <Input
                value={filter}
                onChange={e => setFilter(e.target.value)}
                placeholder={'Search'}
              />
              <InputRightElement children={<Search2Icon color="gray.300" />} />
            </InputGroup>
          </div>
        </GridItem>
        <GridItem colSpan={2}>
          <Box float="right">{button}</Box>
        </GridItem>
      </Grid>

      <Box maxH={500} overflowY="auto" overflowX="hidden">
        <Table {...getTableProps()} size={size} fontSize="xs">
          {tableCaption && <TableCaption>{tableCaption}</TableCaption>}
          <Thead position="sticky" top={0} zIndex={1} backgroundColor="gray.50">
            {headerGroups.map(headerGroup => (
              <Tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <Th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    isNumeric={column.isNumeric}
                  >
                    <chakra.span pr="4">
                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <TriangleDownIcon aria-label="sorted descending" />
                        ) : (
                          <TriangleUpIcon aria-label="sorted ascending" />
                        )
                      ) : null}
                    </chakra.span>
                    <Text>{column.render('Header')}</Text>
                  </Th>
                ))}
              </Tr>
            ))}
          </Thead>
          <Tbody {...getTableBodyProps()}>
            {rows.length === 0 ? (
              <Tr>
                <Td colSpan={_columns.length}>
                  <Center>{`No ${title}`}</Center>
                </Td>
              </Tr>
            ) : (
              ''
            )}
            {rows.map(row => {
              prepareRow(row);
              return (
                <Tr {...row.getRowProps()}>
                  {row.cells.map(cell => (
                    <Td
                      {...cell.getCellProps()}
                      isNumeric={cell.column.isNumeric}
                      fontWeight={cell.column.fontWeight}
                    >
                      {cell.render('Cell')}
                    </Td>
                  ))}
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      </Box>
    </Box>
  );
}
