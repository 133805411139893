import React, { useEffect, useContext } from 'react';
import { Box, SimpleGrid, Stack } from '@chakra-ui/react';
import ActivitiesListTable from '../../components/activity/ActivitiesListTable';
import { UserContext } from '../../providers/UserProvider';

function ActivitiesListPage() {
  const { getActivities } = useContext(UserContext);
  useEffect(() => {
    getActivities();
  }, []);
  return (
    <Box>
      <SimpleGrid columns={1} spacing="24px">
        <ActivitiesListTable title="List of all activities" />
      </SimpleGrid>
    </Box>
  );
}

export default ActivitiesListPage;
