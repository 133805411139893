import { createContext, useState, useContext, useEffect } from 'react';
import { getAuth } from 'firebase/auth';
import { backend_url } from './endpoints';
import axios from 'axios';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import NotyfContext from './NotificationContext';

function UserProvider({ children }) {
  let navigate = useNavigate();
  const auth = getAuth();

  const [user, setUser] = useState(JSON.parse(localStorage.getItem('user')));
  const [socialProvider, setSocialProvider] = useState(null);
  const [season, setSeason] = useState('2021-2022');
  const [loading, setLoading] = useState(true);
  const [loadingMember, setLoadingMember] = useState(true);
  const [loadingUsers, setLoadingUsers] = useState(true);
  const [leaderboard, setLeaderboard] = useState([]);

  const [activityCategories, setActivityCategories] = useState([]);
  const [activityTypes, setActivityTypes] = useState([]);
  const [activities, setActivities] = useState([]);
  const [activityDetails, setActivityDetails] = useState([]);
  const [userActivities, setUserActivities] = useState([]);
  const [pendingActivities, setPendingActivities] = useState([]);
  const [pendingUsers, setPendingUsers] = useState([]);
  const [pendingInvites, setPendingInvites] = useState([]);
  const [pendingInvitesInfo, setPendingInvitesInfo] = useState('');
  const [deletedActivities, setDeletedActivities] = useState([]);
  const [clubActivities, setClubActivities] = useState([]);
  const [clubMembers, setClubMembers] = useState([]);
  const [preloadedPoints, setPreloadedPoints] = useState([]);
  const [deletedClubMembers, setDeletedClubMembers] = useState([]);
  const [notifications, setNotifications] = useState([]);

  const [accountTypes, setAccountTypes] = useState([]);
  const [branches, setBranches] = useState([]);
  const [states, setStates] = useState([]);
  const [clubs, setClubs] = useState([]);
  const [userTypes, setUserTypes] = useState([
    {
      id: 1,
      name: 'Member',
    },
    {
      id: 2,
      name: 'Club Admin',
    },
    {
      id: 3,
      name: 'Branch Admin',
    },
    {
      id: 4,
      name: 'State Admin',
    },
    {
      id: 5,
      name: 'Super Admin',
    },
  ]);

  const notyf = useContext(NotyfContext);

  const getClubs = () => {
    setLoading(true);
    var config = {
      method: 'get',
      url: backend_url + `/clubs`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: user.data.auth_token,
      },
    };

    axios(config)
      .then(res => {
        console.log('clubs', res.data);
        if (res.data.status) {
          setClubs(res.data.data);
        }
      })
      .catch(error => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getAccountTypes = () => {
    var config = {
      method: 'get',
      url: backend_url + `/account_types`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: user.data.auth_token,
      },
    };

    axios(config)
      .then(res => {
        if (res.data.status) {
          setAccountTypes(res.data.data);
        } else {
          notyf.error(res.data.message);
        }
      })
      .catch(error => {
        console.log(error);
        getAccountTypes();
      });
  };

  const getBranches = () => {
    var config = {
      method: 'get',
      url: backend_url + `/branches`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: user.data.auth_token,
      },
    };

    axios(config)
      .then(res => {
        if (res.data.status) {
          setBranches(res.data.data);
        } else {
          notyf.error(res.data.message);
        }
      })
      .catch(error => {
        console.log(error);
        getBranches();
      });
  };
  const getStates = () => {
    var config = {
      method: 'get',
      url: backend_url + `/states`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: user.data.auth_token,
      },
    };

    axios(config)
      .then(res => {
        if (res.data.status) {
          setStates(res.data.data);
        } else {
          notyf.error(res.data.message);
        }
      })
      .catch(error => {
        console.log(error);
        getStates();
      });
  };

  const requestResetPassword = data => {
    axios
      .post(backend_url + `/request_reset_password`, { data })
      .then(res => {
        if (res.data.status) {
          notyf.success(res.data.message);
        } else {
          notyf.error(res.data.message);
        }
      })
      .catch(error => {
        notyf.error('Something went wrong');
        console.log(error);
      });
  };

  const resetPassword = data => {
    axios
      .post(backend_url + `/reset_password`, { data })
      .then(res => {
        if (res.data.status) {
          notyf.success(res.data.message);
          navigate('/sign-in');
        } else {
          notyf.error(res.data.message);
        }
      })
      .catch(error => {
        notyf.error('Something went wrong');
        console.log(error);
      });
  };

  const signIn = data => {
    axios
      .post(backend_url + `/login`, { data })
      .then(res => {
        if (res.data.status) {
          notyf.success('Login Successful');
          if (res.data.data.account_type_id == 1) {
            res.data.data.is_admin = false;
          } else {
            res.data.data.is_admin = true;
          }
          setUser(res.data);
          localStorage.setItem('user', JSON.stringify(res.data));
          navigate('/dashboard');
        } else {
          notyf.error(res.data.message);
        }
      })
      .catch(error => {
        notyf.error('Something went wrong');
        console.log(error);
      });
  };

  const getUserInfo = async () => {
    if (user) {
      setLoading(true);
      var config = {
        method: 'get',
        url: backend_url + `/view_profile`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: user.data.auth_token,
        },
      };
      return axios(config)
        .then(res => {
          if (res.data.status) {
            if (res.data.data.account_type_id == 1) {
              res.data.data.is_admin = false;
            } else {
              res.data.data.is_admin = true;
            }
            localStorage.setItem('user', JSON.stringify(res.data));
            setUser(res.data);
            res.data.data.auth_token = user.data.auth_token;
          }
        })
        .catch(error => {
          console.log(error);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      setLoading(false);
      notyf.error('Please sign-in to continue');
      navigate('/sign-in');
    }
  };

  const signUp = data => {
    axios
      .post(backend_url + `/signup`, data)
      .then(res => {
        if (res.data.status) {
          notyf.success('Signup Successful');
          if (res.data.data.account_type_id == 1) {
            res.data.data.is_admin = false;
          } else {
            res.data.data.is_admin = true;
          }
          localStorage.setItem('user', JSON.stringify(res.data));
          setUser(res.data);
          navigate('/dashboard');
        } else {
          notyf.error(res.data.message);
        }
      })
      .catch(error => {
        console.log(error);
      });
  };

  const logOut = () => {
    notyf.success('Signing out');
    if (auth.currentUser) {
      auth
        .signOut()
        .then(e => {
          console.log('social provider logged out', e);
        })
        .catch(error => {
          console.log(error.message);
        });
    }

    if (user.data) {
      var config = {
        method: 'post',
        url: backend_url + '/logout',
        headers: {
          Authorization: user.data.auth_token,
        },
      };

      axios(config)
        .then(function (res) {
          if (res.data.status) {
            notyf.success(res.data.message);
            navigate('/sign-in');
            setUser(null);
            window.location.reload(false);
          } else {
            // notyf.error(res.data.message);
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    }
    localStorage.setItem('user', null);
    localStorage.setItem('socialProvider', null);
  };

  const updateProfile = values => {
    var _data = {
      data: {
        name: values.name,
        email: values.email,
        year_of_birth: values.year_of_birth,
      },
    };

    var config = {
      method: 'post',
      url: backend_url + `/update_profile`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: user.data.auth_token,
      },
      data: _data,
    };
    axios(config)
      .then(res => {
        if (res.data.status) {
          notyf.success(res.data.message);
          res.data.data.auth_token = user.data.auth_token;
          localStorage.setItem('user', JSON.stringify(res.data));
          setUser(res.data);
          navigate('/dashboard');
        } else {
          notyf.error(res.data.message);
        }
      })
      .catch(error => {
        console.log(error);
      });
  };

  const changePassword = values => {
    var _data = {
      data: {
        old_password: values.old_password,
        new_password: values.new_password,
      },
    };

    var config = {
      method: 'post',
      url: backend_url + `/change_password`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: user.data.auth_token,
      },
      data: _data,
    };
    axios(config)
      .then(res => {
        if (res.data.status) {
          notyf.success(res.data.message);
        } else {
          notyf.error(res.data.message);
        }
      })
      .catch(error => {
        console.log(error);
      });
  };

  const preloadPoints = values => {
    var _data = {
      data: {
        user_id: values.user_id,
        activity_category_id: values.activity_category_id,
        points: values.points,
        comments: values.comments,
      },
    };

    var config = {
      method: 'post',
      url: backend_url + `/user_points`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: user.data.auth_token,
      },
      data: _data,
    };
    axios(config)
      .then(res => {
        if (res.data.status) {
          notyf.success(res.data.message);
          navigate('/points/preloaded');
        } else {
          notyf.error(res.data.message);
        }
      })
      .catch(error => {
        console.log(error);
      });
  };

  const deletePreloadPoints = id => {
    var _data = {
      data: {
        user_point_ids: [id],
      },
    };

    var config = {
      method: 'delete',
      url: backend_url + `/delete_user_points`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: user.data.auth_token,
      },
      data: _data,
    };
    axios(config)
      .then(res => {
        if (res.data.status) {
          notyf.success(res.data.message);
        } else {
          notyf.error(res.data.message);
        }
      })
      .catch(error => {
        console.log(error);
      })
      .finally(() => {
        getPreloadedPoints();
      });
  };

  const getPreloadedPoints = () => {
    setLoading(true);
    var config = {
      method: 'get',
      url: backend_url + '/user_points',
      headers: {
        Authorization: user.data.auth_token,
      },
    };

    axios(config)
      .then(res => {
        if (res.data.status) {
          setPreloadedPoints(res.data.data);
        }
      })
      .catch(error => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getActivityTypes = (category_id = '') => {
    var config = {
      method: 'get',
      url: backend_url + `/activity_types?category_id=${category_id}`,
      headers: {
        Authorization: user.data.auth_token,
      },
    };

    axios(config)
      .then(res => {
        if (res.data.status) {
          setActivityTypes(res.data.data);
        }
      })
      .catch(error => {
        getActivityTypes(category_id);
        console.log(error);
      });
  };

  const getActivityCategories = () => {
    var config = {
      method: 'get',
      url: backend_url + '/activity_categories',
      headers: {
        Authorization: user.data.auth_token,
      },
    };

    axios(config)
      .then(res => {
        if (res.data.status) {
          setActivityCategories(res.data.data);
        }
      })
      .catch(error => {
        getActivityCategories();
        console.log(error);
      });
  };

  const getPendingUsers = () => {
    setLoadingUsers(true);
    var config = {
      method: 'get',
      url: backend_url + '/pending_users',
      headers: {
        Authorization: user.data.auth_token,
      },
    };

    axios(config)
      .then(res => {
        if (res.data.status) {
          setPendingUsers(res.data.data);
        }
        setLoadingUsers(false);
      })
      .catch(error => {
        console.log(error);
      });
  };

  const approveUsers = values => {
    setLoadingUsers(true);
    setPendingUsers([]);
    var _data = {
      data: {
        user_ids: values,
      },
    };
    console.log(_data);

    var config = {
      method: 'post',
      url: backend_url + `/approve_users`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: user.data.auth_token,
      },
      data: _data,
    };
    axios(config)
      .then(res => {
        if (res.data.status) {
          notyf.success(res.data.message);
          getPendingUsers();
        }
      })
      .catch(error => {
        console.log(error);
      });
  };

  const getClubMembers = () => {
    setLoadingMember(true);
    var config = {
      method: 'get',
      url: backend_url + '/club_members',
      headers: {
        Authorization: user.data.auth_token,
      },
    };

    axios(config)
      .then(res => {
        if (res.data.status) {
          setClubMembers(res.data.data);
        }
        setLoadingMember(false);
      })
      .catch(error => {
        console.log(error);
      });
  };

  const getDeletedClubMembers = () => {
    setLoadingMember(true);
    var config = {
      method: 'get',
      url: backend_url + '/deleted_club_members',
      headers: {
        Authorization: user.data.auth_token,
      },
    };

    axios(config)
      .then(res => {
        if (res.data.status) {
          setDeletedClubMembers(res.data.data);
        }
        setLoadingMember(false);
      })
      .catch(error => {
        console.log(error);
      });
  };

  const getLeaderboard = (type = 1) => {
    setLoading(true);
    setLeaderboard([]);
    var config = {
      method: 'get',
      url: backend_url + `/leaderboard?type=${type}`,
      headers: {
        Authorization: user.data.auth_token,
      },
    };

    axios(config)
      .then(res => {
        if (res.data.status) {
          res.data.data.forEach(data => {
            data.type = type;
          });
          setLeaderboard(res.data.data);
        }
        setLoading(false);
      })
      .catch(error => {
        console.log(error);
      });
  };

  const getNotifications = () => {
    setLoading(true);
    var config = {
      method: 'get',
      url: backend_url + `/notifications`,
      headers: {
        Authorization: user.data.auth_token,
      },
    };

    axios(config)
      .then(res => {
        if (res.data.status) {
          setNotifications(res.data.data);
        }
        setLoading(false);
      })
      .catch(error => {
        console.log(error);
      });
  };

  const getActivities = async (type_id = '') => {
    setLoading(true);
    var config = {
      method: 'get',
      url: backend_url + `/activities?type_id=${type_id}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: user.data.auth_token,
      },
    };
    return axios(config)
      .then(res => {
        if (res.data.status) {
          setActivities(res.data.data);
        }
        setLoading(false);
        return true;
      })
      .catch(error => {
        getActivities(type_id);
        console.log(error);
        return false;
      });
  };

  const filterUserActivities = user_id => {
    setLoading(true);
    if (!user_id) {
      user_id = '';
    }
    var config = {
      method: 'get',
      url: backend_url + `/activities_per_user?user_id=${user_id}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: user.data.auth_token,
      },
    };
    axios(config)
      .then(res => {
        if (res.data.status) {
          setActivities(res.data.data);
        }
        setLoading(false);
      })
      .catch(error => {
        console.log(error);
      });
  };
  const filterClubActivityList = (club_id, type = 'club_id', filter) => {
    setLoading(true);
    if (!club_id) {
      club_id = '';
    }

    let url = '';
    if (type == 'club_id') {
      url = `/club_activity_list?${type}=${club_id}&status_id=${filter.status_id}&user_id=${filter.user_id}&activity_category_id=${filter.activity_category_id}&activity_type_id=${filter.activity_type_id}&activity_id=${filter.activity_id}&from_date=${filter.from_date}&to_date=${filter.to_date}`;
    } else if (type == 'club_branch_id') {
      url = `/branch_activity_list?${type}=${club_id}&status_id=${filter.status_id}&user_id=${filter.user_id}&activity_category_id=${filter.activity_category_id}&activity_type_id=${filter.activity_type_id}&activity_id=${filter.activity_id}&from_date=${filter.from_date}&to_date=${filter.to_date}`;
    } else if (type == 'club_state_id') {
      url = `/state_activity_list?${type}=${club_id}&status_id=${filter.status_id}&user_id=${filter.user_id}&activity_category_id=${filter.activity_category_id}&activity_type_id=${filter.activity_type_id}&activity_id=${filter.activity_id}&from_date=${filter.from_date}&to_date=${filter.to_date}`;
    } else {
      url = `/club_activities?${type}=${club_id}&status_id=${filter.status_id}&user_id=${filter.user_id}&activity_category_id=${filter.activity_category_id}&activity_type_id=${filter.activity_type_id}&activity_id=${filter.activity_id}&from_date=${filter.from_date}&to_date=${filter.to_date}`;
    }
    var config = {
      method: 'get',
      url: backend_url + url,
      headers: {
        'Content-Type': 'application/json',
        Authorization: user.data.auth_token,
      },
    };
    axios(config)
      .then(res => {
        if (res.data.status) {
          setClubActivities(res.data.data);
        }
        setLoading(false);
      })
      .catch(error => {
        console.log(error);
      });
  };

  const getUserActivities = () => {
    setUserActivities([]);
    setLoading(true);
    var config = {
      method: 'get',
      url: backend_url + `/user_activities`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: user.data.auth_token,
      },
    };
    axios(config)
      .then(res => {
        if (res.data.status) {
          setUserActivities(res.data.data);
          setLoading(false);
        }
      })
      .catch(error => {
        console.log(error);
      });
  };

  const getPendingActivities = () => {
    setPendingActivities([]);
    setLoading(true);
    var config = {
      method: 'get',
      url: backend_url + `/pending_activities`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: user.data.auth_token,
      },
    };
    axios(config)
      .then(res => {
        if (res.data.status) {
          setPendingActivities(res.data.data);
          setLoading(false);
        }
      })
      .catch(error => {
        console.log(error);
      });
  };

  const getDeletedActivities = () => {
    setDeletedActivities([]);
    setLoading(true);
    var config = {
      method: 'get',
      url:
        backend_url +
        `/${
          user.data.is_admin ? 'club_activities' : 'user_activities'
        }?status_id=3`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: user.data.auth_token,
      },
    };
    axios(config)
      .then(res => {
        if (res.data.status) {
          setDeletedActivities(res.data.data);
          setLoading(false);
        }
      })
      .catch(error => {
        console.log(error);
      });
  };

  const getClubActivities = status_id => {
    setClubActivities([]);
    setLoading(true);
    var config = {
      method: 'get',
      url: backend_url + `/club_activities?status_id=${status_id}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: user.data.auth_token,
      },
    };
    axios(config)
      .then(res => {
        if (res.data.status) {
          setClubActivities(res.data.data);
          setLoading(false);
        }
      })
      .catch(error => {
        console.log(error);
      });
  };

  const filterClubActivities = filter => {
    // setClubActivities([]);
    setLoading(true);
    var config = {
      method: 'get',
      url:
        backend_url +
        `/club_activities?status_id=${filter.status_id}&user_id=${filter.user_id}&activity_category_id=${filter.activity_category_id}&activity_type_id=${filter.activity_type_id}&activity_id=${filter.activity_id}&from_date=${filter.from_date}&to_date=${filter.to_date}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: user.data.auth_token,
      },
    };
    axios(config)
      .then(res => {
        if (res.data.status) {
          setClubActivities(res.data.data);
        }
      })
      .catch(error => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const logNewActivity = values => {
    if (values.supervisorSignOff == true && values.supervisor_name == '') {
      notyf.error("Supervisor's name is required.");
      return false;
    }
    var _data = {
      data: {
        is_auto_approved: values.supervisorSignOff,
        supervisor_name: values.supervisor_name,
        activity_id: values.activity_id,
        activity_date: values.activity_date,
        comment: values.comment,
      },
    };

    var config = {
      method: 'post',
      url: backend_url + `/user_activities`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: user.data.auth_token,
      },
      data: _data,
    };
    axios(config)
      .then(res => {
        if (res.data.status) {
          notyf.success(res.data.message);
          navigate('/activities/history');
        }
      })
      .catch(error => {
        console.log(error);
      });
  };

  const adminLogNewActivity = values => {
    var _data = {
      data: {
        activity_id: values.activity_id,
        activity_date: values.activity_date,
        comment: values.comment,
        user_id: values.user_id,
      },
    };

    var config = {
      method: 'post',
      url: backend_url + `/user_activities`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: user.data.auth_token,
      },
      data: _data,
    };
    axios(config)
      .then(res => {
        if (res.data.status) {
          notyf.success(res.data.message);
          navigate('/dashboard');
        }
      })
      .catch(error => {
        console.log(error);
      });
  };

  const updateActivity = values => {
    var _data = {
      data: {
        activity_id: values.activity_id,
        activity_date: values.activity_date,
        comment: values.comment,
        user_id: values.user_id,
      },
    };

    var config = {
      method: 'put',
      url: backend_url + `/user_activities/${values.id}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: user.data.auth_token,
      },
      data: _data,
    };
    axios(config)
      .then(res => {
        if (res.data.status) {
          notyf.success(res.data.message);
          navigate('/dashboard');
        }
      })
      .catch(error => {
        console.log(error);
      });
  };

  const deleteActivities = (values, table = 'pending_activities') => {
    setLoading(true);
    setPendingActivities([]);
    var _data = {
      data: {
        user_activity_ids: values,
      },
    };
    console.log(_data);

    var config = {
      method: 'delete',
      url: backend_url + `/delete_activities`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: user.data.auth_token,
      },
      data: _data,
    };
    axios(config)
      .then(res => {
        if (res.data.status) {
          if (table == 'pending_activities') {
            notyf.success(res.data.message);
            getPendingActivities();
          } else if (table == 'club_activities') {
            notyf.success('Activity Deleted.');
            getClubActivities(2);
          }
        }
      })
      .catch(error => {
        console.log(error);
      });
  };

  const restoreActivities = values => {
    setLoading(true);
    setDeletedActivities([]);
    var _data = {
      data: {
        user_activity_ids: values,
      },
    };
    console.log(_data);

    var config = {
      method: 'post',
      url: backend_url + `/restore_activities`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: user.data.auth_token,
      },
      data: _data,
    };
    axios(config)
      .then(res => {
        if (res.data.status) {
          notyf.success(res.data.message);
          getDeletedActivities();
        }
      })
      .catch(error => {
        console.log(error);
      });
  };

  const approveActivities = values => {
    setLoading(true);
    setPendingActivities([]);
    var _data = {
      data: {
        user_activity_ids: values,
      },
    };
    console.log(_data);

    var config = {
      method: 'post',
      url: backend_url + `/approve_activities`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: user.data.auth_token,
      },
      data: _data,
    };
    axios(config)
      .then(res => {
        if (res.data.status) {
          notyf.success(res.data.message);
          getPendingActivities();
        }
      })
      .catch(error => {
        console.log(error);
      });
  };

  const inviteMember = values => {
    var _data = {
      data: {
        name: values.name,
        email: values.email,
        year_of_birth: values.year_of_birth,
        account_type_id: values.account_type_id,
        club_id: values.club_id,
        club_branch_id: values.club_branch_id,
        club_state_id: values.club_state_id,
      },
    };

    var config = {
      method: 'post',
      url: backend_url + `/member_invites`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: user.data.auth_token,
      },
      data: _data,
    };
    axios(config)
      .then(res => {
        if (res.data.status) {
          notyf.success(res.data.message);
        } else {
          notyf.error(res.data.message);
        }
      })
      .catch(error => {
        console.log(error);
      });
  };

  const editInviteMember = values => {
    setLoadingUsers(true);
    var _data = {
      data: {
        name: values.name,
        email: values.email,
        year_of_birth: values.year_of_birth,
        account_type_id: values.account_type_id,
        club_id: values.club_id,
        club_branch_id: values.club_branch_id,
        club_state_id: values.club_state_id,
      },
    };

    var config = {
      method: 'put',
      url: backend_url + `/member_invites/${values.id}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: user.data.auth_token,
      },
      data: _data,
    };
    axios(config)
      .then(res => {
        if (res.data.status) {
          notyf.success(res.data.message);
          getPendingInvites();
          navigate('/dashboard');
        } else {
          notyf.error(res.data.message);
        }
      })
      .catch(error => {
        console.log(error);
      })
      .finally(() => {
        setLoadingUsers(false);
      });
  };

  const getPendingInvites = () => {
    setLoadingUsers(true);
    var config = {
      method: 'get',
      url: backend_url + `/pending_invitations`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: user.data.auth_token,
      },
    };
    axios(config)
      .then(res => {
        if (res.data.status) {
          setPendingInvites(res.data.data);
        }
      })
      .catch(error => {
        getPendingInvites();
        console.log(error);
      })
      .finally(() => {
        setLoadingUsers(false);
      });
  };

  const getPendingInvitesInfo = id => {
    setLoadingUsers(true);
    var config = {
      method: 'get',
      url: backend_url + `/member_invites/${id}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: user.data.auth_token,
      },
    };
    axios(config)
      .then(res => {
        if (res.data.status) {
          setPendingInvitesInfo(res.data.data);
        }
      })
      .catch(error => {
        getPendingInvitesInfo(id);
        console.log(error);
      })
      .finally(() => {
        setLoadingUsers(false);
      });
  };

  const deleteInvitations = values => {
    setLoadingUsers(true);
    var _data = {
      data: {
        member_invite_ids: [...values],
      },
    };

    var config = {
      method: 'delete',
      url: backend_url + `/delete_invitations`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: user.data.auth_token,
      },
      data: _data,
    };
    axios(config)
      .then(res => {
        if (res.data.status) {
          getPendingInvites();
          notyf.success(res.data.message);
        } else {
          notyf.error(res.data.message);
        }
      })
      .catch(error => {
        console.log(error);
      })
      .finally(() => {
        setLoadingUsers(false);
      });
  };

  const updateMemberInfo = values => {
    var _data = {
      data: {
        name: values.name,
        email: values.email,
        year_of_birth: values.year_of_birth,
        account_type_id: values.account_type_id,
        club_id: values.club_id,
        club_branch_id: values.club_branch_id,
        club_state_id: values.club_state_id,
      },
    };

    var config = {
      method: 'put',
      url: backend_url + `/users/${values.user_id}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: user.data.auth_token,
      },
      data: _data,
    };
    axios(config)
      .then(res => {
        if (res.data.status) {
          notyf.success(res.data.message);
        } else {
          notyf.error(res.data.message);
        }
      })
      .catch(error => {
        console.log(error);
      });
  };

  const deleteMember = user_id => {
    setLoadingMember(true);
    var config = {
      method: 'delete',
      url: backend_url + `/users/${user_id}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: user.data.auth_token,
      },
    };
    axios(config)
      .then(res => {
        if (res.data.status) {
          notyf.success(res.data.message);
        } else {
          notyf.error(res.data.message);
        }
      })
      .catch(error => {
        console.log(error);
      })
      .finally(() => {
        setLoadingMember(false);
      });
  };

  const restoreMember = user_id => {
    setLoadingMember(true);
    var config = {
      method: 'post',
      url: backend_url + `/users/${user_id}/restore`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: user.data.auth_token,
      },
    };
    axios(config)
      .then(res => {
        if (res.data.status) {
          notyf.success(res.data.message);
        } else {
          notyf.error(res.data.message);
        }
      })
      .catch(error => {
        console.log(error);
      })
      .finally(() => {
        setLoadingMember(false);
      });
  };

  const toggleAutoApprove = (value, club_id = null) => {
    console.log('club_id', club_id);
    setLoading(true);
    var config = {
      method: 'post',
      url:
        backend_url +
        `${
          value === true ? '/enable_auto_approve' : '/disable_auto_approve'
        }?club_id=${club_id ?? user.data.club_id}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: user.data.auth_token,
      },
    };
    axios(config)
      .then(res => {
        if (res.data.status) {
          notyf.success(res.data.message);
        } else {
          notyf.error(res.data.message);
        }
      })
      .catch(error => {
        console.log(error);
      })
      .finally(async () => {
        if (club_id == null) {
          await getUserInfo();
          setLoading(false);
        } else {
          getClubs();
        }
      });
  };
  const getActivityById = async id => {
    setLoading(true);
    var config = {
      method: 'get',
      url: backend_url + `/activities/${id}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: user.data.auth_token,
      },
    };
    return axios(config)
      .then(res => {
        if (res.data.status) {
          if (res.data.data) {
            if (res.data.data.level === 'Club Level') {
              res.data.data.level = 1;
            } else if (res.data.data.level === 'Branch Level') {
              res.data.data.level = 3;
            } else if (res.data.data.level === 'State Level') {
              res.data.data.level = 4;
            }
            setActivityDetails(res.data.data);
          }
          return true;
        } else {
          notyf.error(res.data.message);
          return false;
        }
      })
      .catch(error => {
        notyf.error(error.message);
        console.log(error);
        return false;
      });
  };
  const createActivity = (value, method = 'post', id = null) => {
    setLoading(true);
    var config = {
      method: method,
      url:
        backend_url +
        `${method === 'post' ? '/activities' : `/activities/${id}`}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: user.data.auth_token,
      },
      data: {
        data: value,
      },
    };
    axios(config)
      .then(async res => {
        if (res.data.status) {
          await getActivities();
          navigate('/activities/list');
          notyf.success(res.data.message);
        } else {
          notyf.error(res.data.message);
        }
      })
      .catch(error => {
        notyf.error(error.message);
        console.log(error);
      });
  };

  useEffect(() => {}, [user]);

  return (
    <UserContext.Provider
      value={{
        user,
        notifications,
        activityTypes,
        activities,
        userActivities,
        pendingActivities,
        clubActivities,
        clubMembers,
        leaderboard,
        getActivities,
        getUserActivities,
        getPendingActivities,
        approveActivities,
        setActivities,
        socialProvider,
        logNewActivity,
        season,
        loading,
        loadingMember,
        loadingUsers,
        preloadedPoints,
        pendingUsers,
        signIn,
        signUp,
        logOut,
        changePassword,
        updateProfile,
        inviteMember,
        getClubMembers,
        getLeaderboard,
        getNotifications,
        requestResetPassword,
        resetPassword,
        adminLogNewActivity,
        getActivityTypes,
        deleteActivities,
        updateActivity,
        deletedActivities,
        getDeletedActivities,
        restoreActivities,
        getClubActivities,
        setClubMembers,
        setLoadingMember,
        updateMemberInfo,
        deleteMember,
        deletedClubMembers,
        setDeletedClubMembers,
        getDeletedClubMembers,
        restoreMember,
        preloadPoints,
        getPreloadedPoints,
        getUserInfo,
        deletePreloadPoints,
        getPendingUsers,
        approveUsers,
        filterClubActivities,
        filterUserActivities,
        getActivityCategories,
        setActivityCategories,
        activityCategories,
        getAccountTypes,
        accountTypes,
        getBranches,
        branches,
        getStates,
        states,
        getClubs,
        clubs,
        userTypes,
        pendingInvites,
        pendingInvitesInfo,
        editInviteMember,
        getPendingInvites,
        getPendingInvitesInfo,
        deleteInvitations,
        toggleAutoApprove,
        createActivity,
        getActivityById,
        setActivityDetails,
        activityDetails,
        filterClubActivityList,
      }}
    >
      {children}
    </UserContext.Provider>
  );
}

export default UserProvider;

export const UserContext = createContext({
  user: null,
  socialProvider: null,
  pendingInvites: [],
  pendingInvitesInfo: '',
  userTypes: [],
  states: [],
  branches: [],
  accountTypes: [],
  clubs: [],
  notifications: [],
  activityTypes: [],
  activities: [],
  activityDetails: null,
  userActivities: [],
  pendingActivities: [],
  pendingUsers: [],
  clubActivities: [],
  deletedActivities: [],
  clubMembers: [],
  leaderboard: [],
  preloadedPoints: [],
  season: '',
  loading: true,
  loadingMember: true,
  loadingUsers: true,
  activityCategories: [],
  filterClubActivityList: () => {},
  setActivityCategories: () => {},
  getClubs: () => {},
  getAccountTypes: () => {},
  getBranches: () => {},
  getStates: () => {},
  getActivityCategories: () => {},
  signIn: () => {},
  signUp: () => {},
  logOut: () => {},
  setActivities: () => {},
  getActivities: () => {},
  getUserActivities: () => {},
  getPendingActivities: () => {},
  approveActivities: () => {},
  logNewActivity: () => {},
  changePassword: () => {},
  updateProfile: () => {},
  inviteMember: () => {},
  getClubMembers: () => {},
  getLeaderboard: () => {},
  getNotifications: () => {},
  requestResetPassword: () => {},
  resetPassword: () => {},
  adminLogNewActivity: () => {},
  getActivityTypes: () => {},
  deleteActivities: () => {},
  updateActivity: () => {},
  getDeletedActivities: () => {},
  restoreActivities: () => {},
  getClubActivities: () => {},
  setClubMembers: () => {},
  setLoadingMember: () => {},
  updateMemberInfo: () => {},
  deleteMember: () => {},
  deletedClubMembers: [],
  setDeletedClubMembers: () => {},
  getDeletedClubMembers: () => {},
  restoreMember: () => {},
  preloadPoints: () => {},
  getPreloadedPoints: () => {},
  getUserInfo: () => {},
  deletePreloadPoints: () => {},
  getPendingUsers: () => {},
  approveUsers: () => {},
  filterClubActivities: () => {},
  filterUserActivities: () => {},
  editInviteMember: () => {},
  getPendingInvites: () => {},
  getPendingInvitesInfo: () => {},
  deleteInvitations: () => {},
  toggleAutoApprove: () => {},
  createActivity: () => {},
  getActivityById: () => {},
});
