import { useContext, useEffect } from 'react';
import CardBox from '../common/CardBox';
import { UserContext } from '../../providers/UserProvider';
import PendingUsersTable from './PendingUsersTable';

function PendingUsers() {
  const { getPendingUsers } = useContext(UserContext);

  useEffect(() => {
    getPendingUsers();
  }, []);

  return (
    <CardBox>
      <PendingUsersTable title="Pending Users" />
    </CardBox>
  );
}

export default PendingUsers;
