import { createContext, useState, useEffect } from 'react';
import { backend_url } from './endpoints';
import axios from 'axios';

function AppProvider({ children }) {
  const [clubs, setClubs] = useState([]);
  const [states, setStates] = useState([]);
  const [branches, setBranches] = useState([]);
  const [loading, setLoading] = useState(false);
  const getClubs = (state_id = '', branch_id = '') => {
    setLoading(true);
    setClubs([]);
    axios
      .get(
        backend_url +
          `/clubs_signup?state_id=${state_id}&branch_id=${branch_id}`
      )
      .then(res => {
        console.log('clubs', res.data);
        if (res.data.status) {
          setClubs(res.data.data);
          setLoading(false);
        }
      })
      .catch(error => {
        console.log(error);
        getClubs();
        setLoading(false);
      });
  };

  const getBranches = () => {
    axios
      .get(backend_url + `/branches_signup`)
      .then(res => {
        console.log('getBranches', res.data);
        if (res.data.status) {
          setBranches(res.data.data);
        }
      })
      .catch(error => {
        console.log(error);
        getBranches();
      });
  };

  const getStates = () => {
    axios
      .get(backend_url + `/states_signup`)
      .then(res => {
        console.log('getStates', res.data);
        if (res.data.status) {
          setStates(res.data.data);
        }
      })
      .catch(error => {
        console.log(error);
        getStates();
      });
  };

  useEffect(() => {
    // getClubs();
    getBranches();
    getStates();
  }, []);

  return (
    <AppContext.Provider
      value={{
        clubs,
        states,
        branches,
        getClubs,
        getBranches,
        getStates,
        loading,
      }}
    >
      {children}
    </AppContext.Provider>
  );
}

export default AppProvider;

export const AppContext = createContext({
  clubs: [],
  branches: [],
  states: [],
});
