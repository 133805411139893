import { useContext, useMemo, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Tooltip, IconButton } from '@chakra-ui/react';
import { UserContext } from '../../providers/UserProvider';
import TableLoadingSkeleton from '../loading/TableLoadingSkeleton';
import useWindowSize from '../../helper/useWindowSize';
import DataTable from '../common/DataTable';
import CardBox from '../common/CardBox';
import moment from 'moment';
import { FaTrash } from 'react-icons/fa';

function PreloadedPointsTable({ title, max = 0 }) {
  const location = useLocation();
  const size = useWindowSize();
  const {
    loading,
    getPreloadedPoints,
    preloadedPoints,
    deletePreloadPoints,
  } = useContext(UserContext);

  useEffect(() => {
    getPreloadedPoints();
  }, []);

  const columns = useMemo(() => {
    return [
      {
        Header: 'Date',
        accessor: 'created_at',
        Cell: ({ cell: { value }, row: { values, original } }) => {
          return moment(value).format('DD/MM/YY');
        },
      },
      {
        Header: 'Name',
        accessor: 'name',
      },
      {
        Header: 'Category',
        accessor: 'category',
      },
      {
        Header: 'Points',
        accessor: 'points',
        isNumeric: true,
      },
      {
        Header: '',
        accessor: 'id',
        Cell: ({ cell: { value }, row: { values, original } }) => {
          return (
            <Tooltip label="Delete Preload Points">
              <IconButton
                colorScheme="red"
                size="sm"
                icon={<FaTrash />}
                onClick={() => {
                  deletePreloadPoints(value);
                }}
              />
            </Tooltip>
          );
        },
      },
      //   {
      //     Header: 'Comments',
      //     accessor: 'comments',
      //   },
    ];
  }, []);

  return (
    <>
      {loading ? (
        <TableLoadingSkeleton />
      ) : (
        <CardBox>
          <DataTable
            size={size[0] <= 768 ? 'sm' : 'md'}
            _columns={columns}
            sortOption={{
              id: 'created_at',
              desc: true,
            }}
            _data={max === 0 ? preloadedPoints : preloadedPoints.slice(0, max)}
            filters={['created_at', 'name', 'points']}
            title={title}
          />
        </CardBox>
      )}
    </>
  );
}

export default PreloadedPointsTable;
