import React from 'react';
import { Box } from '@chakra-ui/react';
import ResetPasswordForm from '../components/forms/ResetPasswordForm';
import TextHeader from '../components/common/TextHeader';

function ResetPasswordPage() {
  return (
    <Box>
      <TextHeader
        title="Reset Password"
        subtitle="Please enter your new password"
      />
      <ResetPasswordForm />
    </Box>
  );
}

export default ResetPasswordPage;
