import { FormControl, FormLabel, Select } from '@chakra-ui/react';

function CustomSelect({ name, title = '', options = [], onChange = () => {} }) {
  return (
    <FormControl>
      <FormLabel mt={3}>
        {title !== ''
          ? title.charAt(0).toUpperCase() + title.slice(1)
          : name.charAt(0).toUpperCase() + name.slice(1)}
      </FormLabel>
      <Select
        id={name}
        placeholder={`${title !== '' ? title : 'Select a ' + name}`}
        onChange={onChange}
      >
        {options.map((value, key) => {
          if (typeof value === 'string') {
            return (
              <option key={key} value={key + 1}>
                {value}
              </option>
            );
          } else {
            return (
              <option key={value.id} value={value.id}>
                {value.name}
              </option>
            );
          }
        })}
      </Select>
    </FormControl>
  );
}

export default CustomSelect;
