import { useContext } from 'react';
import { Table, Thead, Tbody, Tr, Th, Td } from '@chakra-ui/react';
import CardBox from '../common/CardBox';
import { UserContext } from '../../providers/UserProvider';
import TableLoadingSkeleton from '../loading/TableLoadingSkeleton';

function UserStats() {
  const { loading, userActivities, season, user } = useContext(UserContext);

  return (
    <CardBox>{renderStats(season, userActivities, loading, user)}</CardBox>
  );
}

const totalCreds = userActivities => {
  var _total = 0;

  userActivities.map(activity => {
    if (activity.status_id == 2) {
      _total = _total + activity.credits;
    }
  });

  return _total;
};

const renderStats = (season, userActivities, loading, user) => {
  return (
    <Table variant="simple" size="sm">
      <Thead>
        <Tr>
          <Th colSpan={2}>{season}</Th>
        </Tr>
      </Thead>
      <Tbody>
        {loading ? (
          <Tr>
            <Td colSpan={3}>
              <TableLoadingSkeleton height="20px" />
            </Td>
          </Tr>
        ) : (
          <>
            <Tr>
              <Td>Activities</Td>
              <Td isNumeric fontWeight="bold">
                {user.data.total_approved_activities +
                  user.data.total_pending_activities}
              </Td>
            </Tr>
            <Tr>
              <Td>Points</Td>
              <Td isNumeric fontWeight="bold">
                {user.data.total_credits}
              </Td>
            </Tr>
            <Tr>
              <Td>Rank</Td>
              <Td isNumeric fontWeight="bold">
                {user.data.rank_number}
              </Td>
            </Tr>
            <Tr>
              <Td>Members</Td>
              <Td isNumeric fontWeight="bold">
                {user.data.total_club_members}
              </Td>
            </Tr>
          </>
        )}
      </Tbody>
    </Table>
  );
};

export default UserStats;
