import React from 'react';
import { Box } from '@chakra-ui/react';
import CompleteProfileForm from '../components/forms/CompleteProfileForm';
import TextHeader from '../components/common/TextHeader';

function CompleteProfilePage() {
  return (
    <Box>
      <TextHeader
        title="Complete Profile"
        subtitle="Fill up your birth and club info"
      />
      <CompleteProfileForm />
    </Box>
  );
}

export default CompleteProfilePage;
