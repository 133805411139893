import React from 'react';
import { Box } from '@chakra-ui/react';
import SignUpForm from '../components/forms/SignUpForm';
import TextHeader from '../components/common/TextHeader';

function SignInPage() {
  return (
    <Box>
      <TextHeader title="Sign Up" subtitle="Create an account" />
      <SignUpForm />
    </Box>
  );
}

export default SignInPage;
