import { useContext } from 'react';
import { HStack, Box, Badge, Text, Divider } from '@chakra-ui/react';
import CardBox from '../common/CardBox';
import { UserContext } from '../../providers/UserProvider';
import { BellIcon } from '@chakra-ui/icons';
import moment from 'moment';

function NotificationsCard() {
  const { user, notifications } = useContext(UserContext);

  return (
    <CardBox>
      <HStack>
        <BellIcon />
        <Text as="b">Notifications</Text>
      </HStack>
      {notifications.length <= 0 ? (
        <Text as="span" color="gray">
          No unread notifications
        </Text>
      ) : null}
      {notifications.map((value, key) => renderNotification(value))}
    </CardBox>
  );
}

const renderNotification = value => {
  return (
    <Box key={`notification-${value.id}`}>
      <Divider mt={2} mb={2} />
      <Badge>{moment(value.created_at).fromNow()}</Badge>
      <Text fontSize="0.8rem">{value.message}</Text>
    </Box>
  );
};

export default NotificationsCard;
