import { Box } from '@chakra-ui/react';

function CardBox({ children }) {
  return (
    <Box bg="gray.50" borderRadius="2xl">
      <Box
        borderRadius="xl"
        border="1px"
        borderColor="#DEDEDE"
        overflow="hidden"
        p={5}
      >
        {children}
      </Box>
    </Box>
  );
}

export default CardBox;
