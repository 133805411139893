import { useContext, useEffect } from 'react';
import CardBox from '../common/CardBox';
import { UserContext } from '../../providers/UserProvider';
import ActivitiesTable from './ActivitiesTable';
import TableLoadingSkeleton from '../loading/TableLoadingSkeleton';
import { Skeleton, Stack, Box } from '@chakra-ui/react';

function RecentActivity() {
  const { getUserActivities, loading } = useContext(UserContext);

  useEffect(() => {
    getUserActivities();
  }, []);

  return (
    <Box>
      {loading === true ? (
        <Box>
          <Skeleton height={'30px'} />
        </Box>
      ) : (
        <CardBox>
          <ActivitiesTable title="Recent Activities" max={5} />
        </CardBox>
      )}
    </Box>
  );
}

export default RecentActivity;
