import React from 'react';
import { Box } from '@chakra-ui/react';
import SignInForm from '../components/forms/SignInForm';
import TextHeader from '../components/common/TextHeader';

function SignInPage() {
  return (
    <Box>
      <TextHeader title="Sign In" subtitle="Login to your account" />
      <SignInForm />
    </Box>
  );
}

export default SignInPage;
