import { useContext, useMemo, useCallback, useEffect } from 'react';
import {
  Tooltip,
  Button,
  TableCaption,
  Box,
  IconButton,
  HStack,
  Badge,
  Link,
  FormControl,
  FormLabel,
  Switch,
} from '@chakra-ui/react';
import CardBox from '../common/CardBox';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { AppContext } from '../../providers/AppProvider';
import { UserContext } from '../../providers/UserProvider';
import TableLoadingSkeleton from '../loading/TableLoadingSkeleton';
import useWindowSize from '../../helper/useWindowSize';
import InviteMemberButton from './InviteMemberButton';
import DataTable from '../common/DataTable';
import moment from 'moment';

import { FaPen, FaTrash, FaPlus } from 'react-icons/fa';
import { MdRefresh } from 'react-icons/md';

function ClubListsTable({ title, max = 0 }) {
  let navigate = useNavigate();
  const size = useWindowSize();
  const { user, toggleAutoApprove, loading, clubs, getClubs } =
    useContext(UserContext);

  const refresh = useCallback(() => {
    getClubs();
  }, []);

  const columns = useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'name',
      },
      {
        Header: 'Enable Auto Approve Activities',
        accessor: 'with_auto_approve_option',
        Cell: ({ cell: { value }, row: { values, original } }) => {
          return (
            <Box>
              <FormControl display="flex" alignItems="center">
                <FormLabel htmlFor="auto_approve" mb="0"></FormLabel>
                <Switch
                  id="auto_approve"
                  defaultChecked={value}
                  onChange={async e => {
                    toggleAutoApprove(e.target.checked, original.id);
                    console.log('original', original);
                    console.log('e', e.target.checked);
                  }}
                />
              </FormControl>
            </Box>
          );
        },
      },
    ],
    []
  );

  const ShowTable = () => {
    return (
      <DataTable
        size={size[0] <= 768 ? 'sm' : 'md'}
        _columns={columns}
        _data={clubs}
        filters={['name', 'with_auto_approve_option']}
        title={title}
        // tableCaption={user.data.is_admin ? <InviteMemberButton /> : null}
        button={
          <HStack>
            <Tooltip label="Refresh List">
              <IconButton
                bgColor="cyan.600"
                color="white"
                size="sm"
                mr={8}
                icon={<MdRefresh />}
                onClick={() => {
                  refresh();
                }}
              />
            </Tooltip>
          </HStack>
        }
      />
    );
  };

  return (
    <CardBox>
      {' '}
      <Box>{loading ? <TableLoadingSkeleton /> : <ShowTable />}</Box>
    </CardBox>
  );
}

export default ClubListsTable;
