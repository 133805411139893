import { useContext, useEffect, useMemo } from 'react';
import { Link, Text, Divider, Box, HStack } from '@chakra-ui/react';
import { UserContext } from '../../providers/UserProvider';
import TableLoadingSkeleton from '../loading/TableLoadingSkeleton';
import useWindowSize from '../../helper/useWindowSize';
import moment from 'moment';
import DataTable from '../common/DataTable';

function LeaderboardTable({ title, main, sub }) {
  const size = useWindowSize();
  const { loading, user, leaderboard } = useContext(UserContext);

  const options = ['Name', 'Club', 'Branch', 'State'];

  const columns = useMemo(
    () => [
      {
        Header: 'Rank',
        accessor: 'rank_number',
        width: 30,
      },
      {
        Header: options[sub - 1],
        accessor: 'name',
        Cell: ({ cell: { value }, row: { values, original } }) => {
          if (original.type == 1) {
            return (
              <Link
                href={`/club/activities?user_id=${original.id}`}
                textDecoration="underline"
              >
                {value}
              </Link>
            );
          } else if (original.type == 2) {
            return (
              <Link
                href={`/club/activities?club_id=${original.id}`}
                textDecoration="underline"
              >
                {value}
              </Link>
            );
          } else if (original.type == 3) {
            return (
              <Link
                href={`/club/activities?club_branch_id=${original.id}`}
                textDecoration="underline"
              >
                {value}
              </Link>
            );
          } else if (original.type == 4) {
            return (
              <Link
                href={`/club/activities?club_state_id=${original.id}`}
                textDecoration="underline"
              >
                {value}
              </Link>
            );
          } else {
            return <Text>{value}</Text>;
          }
        },
      },
      {
        Header: 'Activities',
        accessor: 'total_activities',
      },
      {
        Header: 'Points',
        accessor: 'total_credits',
      },
    ],
    []
  );

  const mobileColumns = useMemo(
    () => [
      {
        Header: 'Rank',
        accessor: 'rank_number',
        width: 30,
      },
      {
        Header: 'Details',
        accessor: 'name',
        Cell: ({ cell: { value }, row: { values, original } }) => (
          <Box mb="15px">
            <Divider />
            <HStack>
              <Box>
                <Text fontSize="14px">
                  <Text as="span" fontWeight="bold" isTruncated>
                    {options[sub - 1]}:
                  </Text>{' '}
                  {value}
                </Text>
                <Text fontSize="14px">
                  <Text as="span" fontWeight="bold">
                    Activities:
                  </Text>{' '}
                  {original.total_activities}
                </Text>
                <Text fontSize="14px">
                  <Text as="span" fontWeight="bold">
                    Points:
                  </Text>{' '}
                  {original.total_credits}
                </Text>
              </Box>
            </HStack>
          </Box>
        ),
      },
    ],
    []
  );

  if (size[0] <= 768) {
    return (
      <Box>
        {loading ? (
          <TableLoadingSkeleton />
        ) : (
          <Box>
            {leaderboard && leaderboard.length <= 0 ? 'No ' + title : title}
            {leaderboard &&
              leaderboard.map((value, index) => {
                return (
                  <Box mb="15px" id={index} key={index}>
                    <Divider />
                    <HStack>
                      <Box>
                        <Text fontSize="14px">
                          <Text as="span" fontWeight="bold" isTruncated>
                            {options[sub - 1]}:
                          </Text>{' '}
                          #{value.rank_number}{' '}
                          {sub == 1 ? (
                            <Link
                              href={`/club/activities?user_id=${value.id}`}
                              textDecoration="underline"
                            >
                              {value.name}
                            </Link>
                          ) : (
                            value.name
                          )}
                          {sub == 2 ? (
                            <Link
                              href={`/club/activities?club_id=${value.id}`}
                              textDecoration="underline"
                            >
                              {value.name}
                            </Link>
                          ) : (
                            value.name
                          )}
                        </Text>
                        <Text fontSize="14px">
                          <Text as="span" fontWeight="bold">
                            Activities:
                          </Text>{' '}
                          {value.total_activities}
                        </Text>
                        <Text fontSize="14px">
                          <Text as="span" fontWeight="bold">
                            Points:
                          </Text>{' '}
                          {value.total_credits}
                        </Text>
                      </Box>
                    </HStack>
                  </Box>
                );
              })}
          </Box>
        )}
      </Box>
    );
  } else {
    return (
      <Box>
        {loading ? (
          <TableLoadingSkeleton />
        ) : (
          <DataTable
            size={size[0] <= 768 ? 'sm' : 'md'}
            _columns={columns}
            _data={leaderboard}
            searchable={false}
            sortOption={{
              id: 'total_credits',
              desc: true,
            }}
            filters={[
              'rank_number',
              'name',
              'total_activities',
              'total_credits',
            ]}
            title={title}
          />
        )}
      </Box>
    );
  }
}

export default LeaderboardTable;
