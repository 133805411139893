import { useContext, useEffect } from 'react';
import CardBox from '../common/CardBox';
import { UserContext } from '../../providers/UserProvider';
import ClubActivitiesTable from './ClubActivitiesTable';
import { useNavigate, useSearchParams } from 'react-router-dom';

function ClubMembersList() {
  const {
    getClubActivities,
    getClubMembers,
    getActivityTypes,
    getActivities,
    getActivityCategories,
    filterClubActivities,
  } = useContext(UserContext);
  let [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    // if (!searchParams.get('user_id') && !searchParams.get('club_id')) {
    //   getClubActivities(2);
    // }
    getClubMembers();
    getActivityCategories();
    getActivityTypes();
    getActivities();
  }, []);

  return (
    <CardBox>
      <ClubActivitiesTable title="Club Activities" />
    </CardBox>
  );
}

export default ClubMembersList;
