import React, { useContext, useEffect } from 'react';
import { Box, Stack, Alert, AlertIcon, Divider } from '@chakra-ui/react';
import Breadcrumbs from '../../components/common/Breadcrumbs';
import RecentActivity from '../../components/activity/RecentActivity';
import { UserContext } from '../../providers/UserProvider';
import PendingActivities from '../../components/activity/PendingActivities';
import PendingUsers from '../../components/user/PendingUsers';
import PendingInvitationsTable from '../../components/user/PendingInvitationsTable';
import LeaderboardPage from './LeaderboardPage';

function DashboardPage() {
  const { user, getClubMembers, getActivityCategories, getActivityTypes } =
    useContext(UserContext);

  useEffect(() => {
    if (user.data.is_approved) {
      getClubMembers();
      getActivityCategories();
      getActivityTypes();
    }
  }, []);
  return (
    <Box>
      <Stack columns={1} spacing="24px" minWidth={{ base: '100%', md: '80%' }}>
        {!user.data.is_approved ? (
          <Alert status="warning">
            <AlertIcon />
            Your account is pending approval - We will notify you by email once
            approval has been made.
          </Alert>
        ) : null}

        {/* club Member */}
        {user.data.is_admin ? null : <RecentActivity />}

        {/* club admin */}
        {user.data.account_type_id == 2 ? <PendingActivities /> : null}
        {user.data.account_type_id == 2 ? <PendingUsers /> : null}

        {/* branch, State, Super Admin */}
        {user.data.account_type_id > 2 ? <LeaderboardPage /> : null}
        {user.data.account_type_id > 2 ? <PendingInvitationsTable /> : null}
      </Stack>
    </Box>
  );
}

export default DashboardPage;
