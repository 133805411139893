import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Button,
  Checkbox,
  Badge,
  Text,
  HStack,
  Grid,
  VStack,
  Box,
  Table,
  Thead,
  Tbody,
  Tooltip,
  IconButton,
  Divider,
  Tr,
  Th,
  Td,
  TableCaption,
  Spacer,
  Flex,
  Center,
} from '@chakra-ui/react';
import { UserContext } from '../../providers/UserProvider';
import TableLoadingSkeleton from '../loading/TableLoadingSkeleton';
import useWindowSize from '../../helper/useWindowSize';
import moment from 'moment';
import { FaTrash } from 'react-icons/fa';
import { MdRefresh } from 'react-icons/md';
function PendingUsersTable({ title, max = 0 }) {
  let navigate = useNavigate();
  const size = useWindowSize();
  const {
    loadingUsers,
    pendingUsers,
    approveUsers,
    deleteActivities,
    getPendingUsers,
  } = useContext(UserContext);
  const [checkedItems, setCheckedItems] = useState([]);
  const [selectedIds, setSelectedIds] = useState([]);
  const [allIds, setAllIds] = useState([]);
  const [count, setCount] = useState(0);
  const [allChecked, setAllChecked] = useState(false);
  const [isIndeterminate, setIsIndeterminate] = useState(false);

  const unselectAll = () => {
    setAllChecked(false);
    setIsIndeterminate(false);
    setCheckedItems([]);
    var _ids = [];
    for (let index = 0; index != pendingUsers.length; index++) {
      _ids[index] = pendingUsers[index].id;
      setAllIds([..._ids]);
      setCheckedItems(oldArray => [...oldArray, false]);
    }
  };

  const handleAllChecked = event => {
    setAllChecked(event.target.checked);
    let _items = checkedItems;
    _items.forEach((_item, index) => {
      _items[index] = event.target.checked;
    });
    if (event.target.checked) {
      setSelectedIds([...allIds]);
    } else {
      setSelectedIds([]);
    }
    setCheckedItems([..._items]);
  };

  const handleCheckFieldElement = event => {
    var count = 0;
    let _items = checkedItems;

    if (event.target.checked) {
      setSelectedIds([...selectedIds, event.target.value]);
    } else {
      var _ids = selectedIds.filter(_id => _id !== event.target.value);
      setSelectedIds([..._ids]);
    }

    _items.forEach((_item, index) => {
      if (index == event.target.id) {
        _items[index] = event.target.checked;
      }
      if (_item == true) {
        count++;
      }
    });

    if (count > 1 && count < checkedItems.length) {
      setIsIndeterminate(true);
    }

    if (count == checkedItems.length) {
      setIsIndeterminate(false);
      setAllChecked(true);
    }

    setCheckedItems([..._items]);
  };

  const editActivity = activity => {
    console.log(activity);
    localStorage.setItem('editActivity', JSON.stringify(activity));
    navigate('/activities/edit');
  };

  useEffect(() => {
    unselectAll();
  }, [pendingUsers]);

  useEffect(() => {
    var count = 0;
    let _items = checkedItems;

    _items.forEach(_item => {
      if (_item == true) {
        count++;
      }
    });

    if (count > 0 && count < checkedItems.length) {
      setIsIndeterminate(true);
    }

    if (count == checkedItems.length) {
      setIsIndeterminate(false);
      setAllChecked(true);
    }

    if (count == 0) {
      setIsIndeterminate(false);
      setAllChecked(false);
    }
    setCount(count);
  }, [checkedItems]);

  if (size[0] <= 768) {
    return (
      <Box>
        <HStack mb="15px">
          <Checkbox
            isChecked={allChecked}
            isIndeterminate={isIndeterminate}
            onChange={handleAllChecked}
          />
          <Divider orientation="vertical" />
          <Text as="b" fontSize="1rem">
            {title}
          </Text>
          <Tooltip label={'Refresh List'}>
            <IconButton
              bgColor="cyan.600"
              color="white"
              aria-label={'Refresh List'}
              size="sm"
              ml={2}
              onClick={() => {
                getPendingUsers();
              }}
              icon={<MdRefresh />}
            />
          </Tooltip>
          {/* <Tooltip label={'Delete selected activities'}>
            <IconButton
              disabled={count > 0 ? false : true}
              bgColor="red.600"
              color="white"
              aria-label={'Delete selected activities'}
              size="sm"
              ml={2}
              onClick={() => {
                deleteActivities(selectedIds);
              }}
              icon={<FaTrash />}
            />
          </Tooltip> */}
        </HStack>

        {loadingUsers ? <TableLoadingSkeleton /> : null}

        {pendingUsers.map((member, index) => {
          return (
            <Box mb="15px">
              <Divider />
              <HStack>
                <Box>
                  <Checkbox
                    id={index}
                    value={member.id}
                    isChecked={checkedItems[index]}
                    onChange={handleCheckFieldElement}
                  />
                </Box>
                <Divider orientation="vertical" />
                <Box>
                  <Text fontSize="14px">
                    <Text as="span" fontWeight="bold" isTruncated>
                      Name:
                    </Text>{' '}
                    {member.name}
                  </Text>
                  <Text fontSize="14px">
                    <Text as="span" fontWeight="bold">
                      Email:
                    </Text>{' '}
                    {member.email}
                  </Text>
                  <Text fontSize="14px">
                    <Text as="span" fontWeight="bold">
                      Status:
                    </Text>{' '}
                    {member.status}
                  </Text>
                  <Box></Box>
                </Box>
              </HStack>
            </Box>
          );
        })}

        <Button
          w="100%"
          bgColor="cyan.600"
          color="white"
          disabled={count > 0 ? false : true}
          onClick={() => {
            approveUsers(selectedIds);
          }}
        >
          Approve Selected {count > 0 ? `(${count})` : null}
        </Button>
      </Box>
    );
  } else {
    return (
      <Table
        variant="simple"
        size={size[0] <= 768 ? 'sm' : 'md'}
        fontSize="small"
      >
        <TableCaption>
          <Button
            w="100%"
            bgColor="cyan.600"
            color="white"
            disabled={count > 0 ? false : true}
            onClick={() => {
              approveUsers(selectedIds);
            }}
          >
            Approve Selected {count > 0 ? `(${count})` : null}
          </Button>
        </TableCaption>
        <Thead>
          <Tr>
            <Th colSpan={3} pb={5}>
              <Text as="b" fontSize="1rem">
                {title}
              </Text>
            </Th>
            <Th colSpan={2}>
              <Flex alignItems={'right'}>
                <Spacer />
                <Tooltip label={'Refresh List'}>
                  <IconButton
                    bgColor="cyan.600"
                    color="white"
                    aria-label={'Refresh List'}
                    size="sm"
                    ml={2}
                    onClick={() => {
                      getPendingUsers();
                    }}
                    icon={<MdRefresh />}
                  />
                </Tooltip>
                {/* <Tooltip label={'Delete selected activities'}>
                  <IconButton
                    disabled={count > 0 ? false : true}
                    bgColor="red.600"
                    color="white"
                    aria-label={'Delete selected activities'}
                    size="sm"
                    ml={2}
                    onClick={() => {
                      deleteActivities(selectedIds);
                    }}
                    icon={<FaTrash />}
                  />
                </Tooltip> */}
              </Flex>
            </Th>
          </Tr>
          <Tr>
            <Th>
              <Checkbox
                isChecked={allChecked}
                isIndeterminate={isIndeterminate}
                onChange={handleAllChecked}
              />
            </Th>
            <Th>Name</Th>
            <Th>Email</Th>
            <Th>Role</Th>
          </Tr>
        </Thead>
        <Tbody>
          {loadingUsers ? (
            <Tr>
              <Td colSpan={5}>
                <TableLoadingSkeleton />
              </Td>
            </Tr>
          ) : null}
          {loadingUsers === false && pendingUsers.length === 0 ? (
            <Tr>
              <Td colSpan={5}>
                <Center>No {title}</Center>
              </Td>
            </Tr>
          ) : null}
          {loadingUsers === false &&
            pendingUsers.map((member, index) => {
              return (
                <Tr
                  key={'row' + index}
                  style={{ cursor: 'pointer' }}
                  _hover={{ bgColor: 'cyan.400' }}
                >
                  <Td>
                    <Checkbox
                      id={index}
                      value={member.id}
                      isChecked={checkedItems[index]}
                      onChange={handleCheckFieldElement}
                    />
                  </Td>
                  <Td>{member.name}</Td>

                  <Td>{member.email}</Td>
                  <Td fontWeight="bold">
                    {member.is_admin ? 'Admin' : 'Member'}
                  </Td>
                </Tr>
              );
            })}
        </Tbody>
      </Table>
    );
  }
}

export default PendingUsersTable;
