import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Button,
  Spacer,
  Image,
  HStack,
  Container,
  Drawer,
  DrawerBody,
  DrawerOverlay,
  DrawerContent,
  useDisclosure,
  theme,
} from '@chakra-ui/react';
import { HamburgerIcon, CloseIcon } from '@chakra-ui/icons';
// import { ColorModeSwitcher } from '../../theme/ColorModeSwitcher';
import IMAGE_ASSETS from '../../../assets/image-list';

function MobileNav() {
  let navigate = useNavigate();
  const { isOpen, onOpen, onClose, onToggle } = useDisclosure();

  return (
    <Container maxW="container.xl">
      <HStack pt={5} pb={5}>
        <Image
          boxSize="70px"
          objectFit="fit"
          src={IMAGE_ASSETS.src.logo}
          alt="YIP LOGO"
          onClick={() => {
            navigate('/');
          }}
          style={{ cursor: 'pointer' }}
        />
        <Spacer />
        {/* <ColorModeSwitcher justifySelf="flex-end" /> */}

        <Button variant="solid" onClick={() => onOpen()} m={4}>
          <HamburgerIcon />
        </Button>
      </HStack>

      <Drawer onClose={onClose} isOpen={isOpen} size="full" placement="top">
        <DrawerOverlay />
        <DrawerContent>
          <Container maxW="container.xl">
            <HStack pt={5} pb={5}>
              <Image
                boxSize="35px"
                objectFit="cover"
                src={IMAGE_ASSETS.src.logo}
                alt="YIP LOGO"
              />
              <Spacer />
              {/* <ColorModeSwitcher justifySelf="flex-end" /> */}
              <Button onClick={() => onToggle()} m={4}>
                <CloseIcon />
              </Button>
            </HStack>
            <DrawerBody>
              <Button
                onClick={() => {
                  onClose();
                  navigate('/sign-in');
                }}
                w="100%"
                variant="solid"
                size="lg"
              >
                Sign In
              </Button>
              <Button
                onClick={() => {
                  onClose();
                  navigate('/sign-up');
                }}
                w="100%"
                mt={2}
                bgColor={theme.colors.cyan[600]}
                color="white"
                variant="solid"
                size="lg"
              >
                Sign Up
              </Button>
            </DrawerBody>
          </Container>
        </DrawerContent>
      </Drawer>
    </Container>
  );
}
export default MobileNav;
