import { useContext, useMemo, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import {
  Button,
  IconButton,
  Badge,
  Text,
  HStack,
  Table,
  Thead,
  Tbody,
  Tooltip,
  Tr,
  Th,
  Td,
  TableCaption,
  chakra,
  Box,
  Divider,
} from '@chakra-ui/react';
import { TriangleDownIcon, TriangleUpIcon } from '@chakra-ui/icons';
import { useTable, useSortBy } from 'react-table';
import { UserContext } from '../../providers/UserProvider';
import ActivityButton from './ActivityButton';
import RepeatActivityButton from './RepeatActivityButton';
import TableLoadingSkeleton from '../loading/TableLoadingSkeleton';
import useWindowSize from '../../helper/useWindowSize';
import DataTable from '../common/DataTable';
import moment from 'moment';
import { MdRefresh } from 'react-icons/md';

function ActivitiesTable({ title, max = 0 }) {
  const location = useLocation();
  const size = useWindowSize();
  const { user, loading, userActivities, getUserActivities } =
    useContext(UserContext);

  const columns = useMemo(
    () => [
      {
        Header: 'Date',
        accessor: 'activity_date',
        Cell: ({ cell: { value }, row: { values, original } }) => {
          return moment(value).format('DD/MM/YY');
        },
      },
      {
        Header: 'Activity',
        accessor: 'activity_name',
      },
      {
        Header: 'Points',
        accessor: 'credits',
        isNumeric: true,
        fontWeight: 'bold',
        Cell: ({ cell: { value }, row: { values, original } }) => {
          return original.status_id === 2 ? (
            value
          ) : (
            <Badge colorScheme="yellow">Pending</Badge>
          );
        },
      },
      {
        Header: '',
        accessor: 'id',
        isNumeric: true,
        Cell: ({ cell: { value }, row: { values, original } }) => (
          <RepeatActivityButton
            data={original}
            disabled={original.is_activity ? false : true}
          />
        ),
      },
    ],
    []
  );

  const mobileColumns = useMemo(
    () => [
      {
        Header: 'Date',
        accessor: 'activity_date',
        Cell: ({ cell: { value }, row: { values, original } }) => {
          return moment(value).format('DD/MM/YY');
        },
      },
      {
        Header: 'Details',
        accessor: 'activity_name',
        Cell: ({ cell: { value }, row: { values, original } }) => (
          <Box mb="15px">
            <Divider />
            <HStack>
              <Box>
                <Text fontSize="14px">
                  <Text as="span" fontWeight="bold" isTruncated>
                    Activity:
                  </Text>{' '}
                  {value}
                </Text>
                <Text fontSize="14px">
                  <Text as="span" fontWeight="bold">
                    Points:
                  </Text>{' '}
                  {original.status_id === 2 ? (
                    original.credits
                  ) : (
                    <Badge colorScheme="yellow">Pending</Badge>
                  )}
                </Text>
                <Text fontSize="14px">
                  <RepeatActivityButton
                    data={original}
                    disabled={original.is_activity ? false : true}
                  />
                </Text>
              </Box>
            </HStack>
          </Box>
        ),
      },
    ],
    []
  );

  const getTableButtons = () => {
    return (
      <>
        <ActivityButton />
        <Tooltip label={'Refresh List'}>
          <IconButton
            bgColor="cyan.600"
            color="white"
            aria-label={'Refresh List'}
            size="sm"
            ml={2}
            mr={5}
            onClick={() => {
              getUserActivities();
            }}
            disabled={
              !user.data.is_admin && !user.data.is_approved ? true : false
            }
            icon={<MdRefresh />}
          />
        </Tooltip>
      </>
    );
  };

  if (loading) {
    return <TableLoadingSkeleton />;
  }
  if (size[0] <= 768) {
    return (
      <DataTable
        size={size[0] <= 768 ? 'sm' : 'md'}
        _columns={mobileColumns}
        _data={max === 0 ? userActivities : userActivities.slice(0, max)}
        filters={['activity_date', 'activity_name', 'credits']}
        title={title}
        button={getTableButtons()}
        sortOption={{
          id: 'activity_date',
          desc: true,
        }}
        tableCaption={
          location.pathname === '/activities/history' ? null : (
            <Link to="/activities/history">
              <Text color="cyan.600" href="#" textAlign="center">
                My Activities History
              </Text>
            </Link>
          )
        }
      />
    );
  } else {
    return (
      <Box>
        {loading ? (
          <TableLoadingSkeleton />
        ) : (
          <DataTable
            size={size[0] <= 768 ? 'sm' : 'md'}
            _columns={columns}
            _data={max === 0 ? userActivities : userActivities.slice(0, max)}
            filters={['activity_date', 'activity_name', 'credits']}
            title={title}
            button={getTableButtons()}
            sortOption={{
              id: 'activity_date',
              desc: true,
            }}
            tableCaption={
              location.pathname === '/activities/history' ? null : (
                <Link
                  to="/activities/history"
                  disabled={
                    !user.data.is_admin && !user.data.is_approved ? true : false
                  }
                >
                  <Text color="cyan.600" href="#" textAlign="center">
                    My Activities History
                  </Text>
                </Link>
              )
            }
          />
        )}
      </Box>
    );
  }
}

export default ActivitiesTable;
