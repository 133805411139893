import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

//layouts
import PublicLayout from './components/layouts/PublicNav/PublicLayout';
import DashboardLayout from './components/layouts/DashboardNav/DashboardLayout';

//public pages
import HomePage from './pages/HomePage';
import SignInPage from './pages/SignInPage';
import SignUpPage from './pages/SignUpPage';
import CompleteProfilePage from './pages/CompleteProfilePage';

//Dashboard Pages
import DashboardPage from './pages/dashboard/DashboardPage';
import NotificationsPage from './pages/dashboard/NotificationsPage';
import MyActivitiesHistoryPage from './pages/dashboard/MyActivitiesHistoryPage';
import ActivitiesListPage from './pages/dashboard/ActivitiesListPage';
import LogActivityPage from './pages/dashboard/LogActivityPage';
import InviteMemberPage from './pages/dashboard/InviteMemberPage';
import EditInviteMemberForm from './components/invite/EditInviteMemberForm';
import EditMemberPage from './pages/dashboard/EditMemberPage';
import ClubMembers from './pages/dashboard/ClubMembers';
import DeletedMembersList from './components/club/DeletedMembersList';
import AddActivityPage from './pages/dashboard/AddActivityPage';
import EditActivityPage from './pages/dashboard/EditActivityPage';
import LeaderboardPage from './pages/dashboard/LeaderboardPage';
import DeletedActivitiesPage from './pages/dashboard/DeletedActivitiesPage';
import ClubActivitiesPage from './pages/dashboard/ClubActivitiesPage';
import PreloadPoints from './pages/dashboard/PreloadPoints';
import PreloadedPointsPage from './pages/dashboard/PreloadedPointsPage';
import ClubSettings from './pages/dashboard/ClubSettings';
import ClubLists from './pages/dashboard/ClubLists';
import CreateActivityPage from './pages/dashboard/CreateActivityPage';
import UpdateActivityPage from './pages/dashboard/UpdateActivityPage';

//providers
import UserProvider from './providers/UserProvider';
import AppProvider from './providers/AppProvider';

import { ChakraProvider, Box, extendTheme } from '@chakra-ui/react';

import 'notyf/notyf.min.css';
import UpdateProfilePage from './pages/dashboard/UpdateProfilePage';
import ChangePasswordPage from './pages/dashboard/ChangePasswordPage';
import InviteMemberSignUp from './pages/InviteMemberSignUp';
import RequestResetPasswordPage from './pages/RequestResetPasswordPage';
import ResetPasswordPage from './pages/ResetPasswordPage';
const theme = extendTheme({
  styles: {
    global: props => ({
      'html, body': {
        fontSize: 'md',
        bg: props.colorMode === 'dark' ? 'gray.600' : '#ECF2F9',
        color: props.colorMode === 'dark' ? 'white' : 'black',
        lineHeight: 'tall',
      },
    }),
  },
});

const App = () => {
  return (
    <Box>
      <BrowserRouter>
        <AppProvider>
          <UserProvider>
            <ChakraProvider theme={theme}>
              <Routes>
                <Route
                  exact
                  path="/"
                  element={
                    <PublicLayout>
                      <HomePage />
                    </PublicLayout>
                  }
                />
                <Route
                  exact
                  path="/sign-in"
                  element={
                    <PublicLayout>
                      <SignInPage />
                    </PublicLayout>
                  }
                />
                <Route
                  exact
                  path="/sign-up"
                  element={
                    <PublicLayout>
                      <SignUpPage />
                    </PublicLayout>
                  }
                />
                <Route
                  exact
                  path="/complete-profile"
                  element={
                    <PublicLayout>
                      <CompleteProfilePage />
                    </PublicLayout>
                  }
                />
                <Route
                  exact
                  path="/forgot-password"
                  element={
                    <PublicLayout>
                      <RequestResetPasswordPage />
                    </PublicLayout>
                  }
                />
                <Route
                  exact
                  path="/reset-password"
                  element={
                    <PublicLayout>
                      <ResetPasswordPage />
                    </PublicLayout>
                  }
                />
                <Route
                  exact
                  path="/invites/account"
                  element={
                    <PublicLayout>
                      <InviteMemberSignUp />
                    </PublicLayout>
                  }
                />
                <Route
                  exact
                  path="/dashboard"
                  element={
                    <DashboardLayout>
                      <DashboardPage />
                    </DashboardLayout>
                  }
                />
                <Route
                  exact
                  path="/notifications"
                  element={
                    <DashboardLayout>
                      <NotificationsPage />
                    </DashboardLayout>
                  }
                />
                <Route
                  exact
                  path="/activities/history"
                  element={
                    <DashboardLayout>
                      <MyActivitiesHistoryPage />
                    </DashboardLayout>
                  }
                />
                <Route
                  exact
                  path="/activities/list"
                  element={
                    <DashboardLayout>
                      <ActivitiesListPage />
                    </DashboardLayout>
                  }
                />
                <Route
                  exact
                  path="/activities/create"
                  element={
                    <DashboardLayout>
                      <CreateActivityPage />
                    </DashboardLayout>
                  }
                />
                <Route
                  exact
                  path="/activities/update/:id"
                  element={
                    <DashboardLayout>
                      <UpdateActivityPage />
                    </DashboardLayout>
                  }
                />
                <Route
                  exact
                  path="/club/activities"
                  element={
                    <DashboardLayout>
                      <ClubActivitiesPage />
                    </DashboardLayout>
                  }
                />
                <Route
                  exact
                  path="/activities/deleted"
                  element={
                    <DashboardLayout>
                      <DeletedActivitiesPage />
                    </DashboardLayout>
                  }
                />
                <Route
                  exact
                  path="/activities/log-new"
                  element={
                    <DashboardLayout>
                      <LogActivityPage />
                    </DashboardLayout>
                  }
                />
                <Route
                  exact
                  path="/activities/add-new"
                  element={
                    <DashboardLayout>
                      <AddActivityPage />
                    </DashboardLayout>
                  }
                />
                <Route
                  exact
                  path="/points/preload"
                  element={
                    <DashboardLayout>
                      <PreloadPoints />
                    </DashboardLayout>
                  }
                />
                <Route
                  exact
                  path="/points/preloaded"
                  element={
                    <DashboardLayout>
                      <PreloadedPointsPage />
                    </DashboardLayout>
                  }
                />
                <Route
                  exact
                  path="/activities/edit"
                  element={
                    <DashboardLayout>
                      <EditActivityPage />
                    </DashboardLayout>
                  }
                />
                <Route
                  exact
                  path="/club/invite"
                  element={
                    <DashboardLayout>
                      <InviteMemberPage />
                    </DashboardLayout>
                  }
                />
                <Route
                  exact
                  path="/club/settings"
                  element={
                    <DashboardLayout>
                      <ClubSettings />
                    </DashboardLayout>
                  }
                />
                <Route
                  exact
                  path="/club/lists"
                  element={
                    <DashboardLayout>
                      <ClubLists />
                    </DashboardLayout>
                  }
                />
                <Route
                  exact
                  path="/club/invite/edit"
                  element={
                    <DashboardLayout>
                      <EditInviteMemberForm />
                    </DashboardLayout>
                  }
                />
                <Route
                  exact
                  path="/club/member/edit"
                  element={
                    <DashboardLayout>
                      <EditMemberPage />
                    </DashboardLayout>
                  }
                />
                <Route
                  exact
                  path="/profile"
                  element={
                    <DashboardLayout>
                      <UpdateProfilePage />
                    </DashboardLayout>
                  }
                />
                <Route
                  exact
                  path="/change-password"
                  element={
                    <DashboardLayout>
                      <ChangePasswordPage />
                    </DashboardLayout>
                  }
                />
                <Route
                  exact
                  path="/club/members"
                  element={
                    <DashboardLayout>
                      <ClubMembers />
                    </DashboardLayout>
                  }
                />
                <Route
                  exact
                  path="/club/members/deleted"
                  element={
                    <DashboardLayout>
                      <DeletedMembersList />
                    </DashboardLayout>
                  }
                />
                <Route
                  exact
                  path="/leaderboards"
                  element={
                    <DashboardLayout>
                      <LeaderboardPage />
                    </DashboardLayout>
                  }
                />
              </Routes>
            </ChakraProvider>
          </UserProvider>
        </AppProvider>
      </BrowserRouter>
    </Box>
  );
};

export default App;
