import React from 'react';
import { Box } from '@chakra-ui/react';
import ForgotPasswordForm from '../components/forms/ForgotPasswordForm';
import TextHeader from '../components/common/TextHeader';

function RequestResetPasswordPage() {
  return (
    <Box>
      <TextHeader title="Forgot Password" subtitle="Please enter your email" />
      <ForgotPasswordForm />
    </Box>
  );
}

export default RequestResetPasswordPage;
