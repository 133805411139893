import { useContext } from 'react';
import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Tooltip,
  Box,
  Button,
  Divider,
  Text,
} from '@chakra-ui/react';
import { getAuth } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import { ChevronDownIcon } from '@chakra-ui/icons';
import { UserContext } from '../../../providers/UserProvider';

function BranchStateSuperAdminMenu() {
  const auth = getAuth();
  let navigate = useNavigate();
  const { logOut, user } = useContext(UserContext);

  return (
    <Box>
      <Menu>
        <MenuButton
          w="100%"
          as={Button}
          aria-label="User"
          textAlign={'left'}
          onClick={() => {
            navigate('/activities/list');
          }}
          disabled={
            !user.data.is_admin && !user.data.is_approved ? true : false
          }
        >
          Activities List
        </MenuButton>
      </Menu>

      <Divider my={2} />
      <Menu>
        <Tooltip label="Club Menu">
          <MenuButton
            w="100%"
            as={Button}
            aria-label="User"
            rightIcon={<ChevronDownIcon />}
            textAlign={'left'}
            disabled={
              !user.data.is_admin && !user.data.is_approved ? true : false
            }
          >
            Users
          </MenuButton>
        </Tooltip>
        <MenuList>
          <MenuItem
            onClick={() => {
              navigate('/club/members');
            }}
          >
            Members
          </MenuItem>
          <MenuItem
            onClick={() => {
              navigate('/club/members/deleted');
            }}
          >
            Deleted Members
          </MenuItem>
          {user.data.is_admin ? (
            <MenuItem
              onClick={() => {
                navigate('/club/invite');
              }}
            >
              Invite Member
            </MenuItem>
          ) : null}
        </MenuList>
      </Menu>
      <Divider my={2} />
      <Menu>
        <MenuButton
          w="100%"
          as={Button}
          aria-label="club lists"
          textAlign={'left'}
          onClick={() => {
            navigate('/club/lists');
          }}
        >
          Club Lists
        </MenuButton>
        <Divider my={2} />
        <MenuButton
          w="100%"
          as={Button}
          aria-label="User"
          textAlign={'left'}
          onClick={() => {
            navigate('/leaderboards');
          }}
          disabled={
            !user.data.is_admin && !user.data.is_approved ? true : false
          }
        >
          Leaderboard
        </MenuButton>
      </Menu>
      <Divider my={2} />
    </Box>
  );
}
export default BranchStateSuperAdminMenu;
