import { useState } from 'react';
import { Field } from 'formik';
import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
  IconButton,
  InputRightElement,
  InputGroup,
} from '@chakra-ui/react';
import { FaEye, FaEyeSlash } from 'react-icons/fa';

function InputText({
  name,
  title = '',
  type = 'text',
  disabled = false,
  value = null,
}) {
  const [show, setShow] = useState(false);
  const handleClick = () => setShow(!show);

  function getPlaceholder() {
    if (title !== '' && disabled === false) {
      return title.charAt(0).toUpperCase() + title.slice(1);
    } else if (value !== null && disabled === true) {
      return value;
    } else {
      return name.charAt(0).toUpperCase() + name.slice(1);
    }
  }

  return (
    <>
      <Field name={name}>
        {({ field, form }) => (
          <FormControl isInvalid={form.errors[name] && form.touched[name]}>
            <FormLabel htmlFor={name} mt={3}>
              {title !== ''
                ? title.charAt(0).toUpperCase() + title.slice(1)
                : name.charAt(0).toUpperCase() + name.slice(1)}
            </FormLabel>
            <InputGroup size="md">
              <Input
                {...field}
                type={show ? 'text' : type}
                id={name}
                placeholder={getPlaceholder()}
                disabled={disabled}
              />
              {type !== 'password' ? null : (
                <InputRightElement>
                  {show ? (
                    <IconButton
                      mr="1rem"
                      h="1.75rem"
                      size="md"
                      fontSize="lg"
                      variant="ghost"
                      color="current"
                      onClick={handleClick}
                      icon={<FaEyeSlash />}
                    />
                  ) : (
                    <IconButton
                      mr="1rem"
                      h="1.75rem"
                      size="md"
                      fontSize="lg"
                      variant="ghost"
                      color="current"
                      onClick={handleClick}
                      icon={<FaEye />}
                    />
                  )}
                </InputRightElement>
              )}
            </InputGroup>
            <FormErrorMessage>{form.errors[name]}</FormErrorMessage>
          </FormControl>
        )}
      </Field>
    </>
  );
}

export default InputText;
