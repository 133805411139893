import React, { useContext, useEffect } from 'react';
import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import { useNavigate } from 'react-router-dom';
import InputText from '../formik/InputText';
import InputSelect from '../formik/InputSelect';
import CustomSelect from '../formik/CustomSelect';
import SubmitButton from '../formik/SubmitButton';
import CardBox from '../common/CardBox';
import { UserContext } from '../../providers/UserProvider';
import TableLoadingSkeleton from '../loading/TableLoadingSkeleton';
import { Text } from '@chakra-ui/react';

function AddNewActivity() {
  let navigate = useNavigate();
  const {
    activityCategories,
    activityTypes,
    activities,
    getActivities,
    setActivities,
    adminLogNewActivity,
    getActivityTypes,
    getActivityCategories,
    clubMembers,
    loadingMember,
    getClubMembers,
  } = useContext(UserContext);

  useEffect(() => {
    if (clubMembers.length === 0) {
      getClubMembers();
    }
    if (activityTypes.length === 0) {
      getActivityTypes();
    }
    if (activityCategories.length === 0) {
      getActivityCategories();
    }
  }, []);

  return (
    <CardBox bg="gray.50" borderRadius="2xl" p={5}>
      <Text as="b" fontSize="1rem" pr={1}>
        Add Activity
      </Text>
      {loadingMember ? (
        <TableLoadingSkeleton />
      ) : (
        <Formik
          initialValues={{
            user_id: '',
            activity_id: '',
            activity_date: '',
            comment: '',
          }}
          validationSchema={SignupSchema}
          onSubmit={(values, actions) => {
            console.log(values);
            adminLogNewActivity(values);
            setTimeout(() => {
              actions.setSubmitting(false);
              actions.resetForm();
            }, 1000);
          }}
        >
          {({ isSubmitting }) => (
            <Form>
              <InputSelect
                name="user_id"
                title="Club Member"
                options={clubMembers}
                onChange={e => {
                  if (e.target.value) {
                    getActivities(e.target.value);
                  } else {
                    setActivities([]);
                  }
                }}
              />
              <CustomSelect
                name="activity_category"
                title="Activity Category"
                options={activityCategories}
                onChange={e => {
                  if (e.target.value) {
                    getActivityTypes(e.target.value);
                  } else {
                    setActivities([]);
                  }
                }}
              />
              <CustomSelect
                name="activity_type"
                title="Activity Type"
                options={activityTypes}
                onChange={e => {
                  if (e.target.value) {
                    getActivities(e.target.value);
                  } else {
                    setActivities([]);
                  }
                }}
              />
              <InputSelect
                name="activity_id"
                title="Choose Activity"
                options={activities}
              />
              <InputText
                name="activity_date"
                title="Activity Date"
                type="date"
              />
              <InputText name="comment" title="Comment (Optional)" />
              <SubmitButton isSubmitting={isSubmitting} />
            </Form>
          )}
        </Formik>
      )}
    </CardBox>
  );
}

const SignupSchema = Yup.object().shape({
  user_id: Yup.string().required('Required'),
  activity_id: Yup.string().required('Required'),
  activity_date: Yup.string().required('Required'),
});

export default AddNewActivity;
