import React from 'react';
import { Box, SimpleGrid } from '@chakra-ui/react';
import UpdateActivity from '../../components/forms/UpdateActivity';

function EditActivityPage() {
  return (
    <Box>
      <SimpleGrid columns={1} spacing="24px">
        <UpdateActivity />
      </SimpleGrid>
    </Box>
  );
}

export default EditActivityPage;
