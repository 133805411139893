import { useContext, useMemo, useCallback, useEffect } from 'react';
import {
  Tooltip,
  Button,
  TableCaption,
  Box,
  IconButton,
  HStack,
  Badge,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../../providers/UserProvider';
import TableLoadingSkeleton from '../loading/TableLoadingSkeleton';
import useWindowSize from '../../helper/useWindowSize';
import InviteMemberButton from './InviteMemberButton';
import DataTable from '../common/DataTable';
import moment from 'moment';

import { FaPen, FaTrash, FaPlus } from 'react-icons/fa';
import { MdRefresh, MdRestore } from 'react-icons/md';

function DeletedMembersTable({ title, max = 0 }) {
  let navigate = useNavigate();
  const size = useWindowSize();
  const {
    loadingMember,
    user,
    deletedClubMembers,
    getDeletedClubMembers,
    restoreMember,
  } = useContext(UserContext);

  const _restoreMember = useCallback(value => {
    restoreMember(value);
    refresh();
  }, []);

  const refresh = useCallback(() => {
    getDeletedClubMembers();
  }, []);

  const columns = useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'name',
      },
      {
        Header: 'Email',
        accessor: 'email',
      },
      {
        Header: 'Role',
        accessor: 'is_admin',
        Cell: ({ cell: { value }, row: { values, original } }) => {
          if (value === true) {
            return <Badge colorScheme={'yellow'}>Admin</Badge>;
          } else {
            return <Badge>Member</Badge>;
          }
        },
      },
      {
        Header: '',
        accessor: 'id',
        Cell: ({ cell: { value }, row: { values, original } }) => {
          return (
            <HStack spacing={1}>
              <Tooltip label="Restore Member">
                <IconButton
                  colorScheme="green"
                  size="sm"
                  icon={<MdRestore />}
                  onClick={() => {
                    _restoreMember(value);
                  }}
                />
              </Tooltip>
            </HStack>
          );
        },
      },
    ],
    []
  );

  const ShowTable = () => {
    return (
      <DataTable
        size={size[0] <= 768 ? 'sm' : 'md'}
        _columns={columns}
        _data={deletedClubMembers}
        filters={['name', 'email', 'is_admin']}
        title={title}
        // tableCaption={user.data.is_admin ? <InviteMemberButton /> : null}
        button={
          <HStack>
            <Tooltip label="Refresh List">
              <IconButton
                bgColor="cyan.600"
                color="white"
                size="sm"
                mr={8}
                icon={<MdRefresh />}
                onClick={() => {
                  refresh();
                }}
              />
            </Tooltip>
          </HStack>
        }
      />
    );
  };

  return <Box>{loadingMember ? <TableLoadingSkeleton /> : <ShowTable />}</Box>;
}

export default DeletedMembersTable;
