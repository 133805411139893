import { useContext, useEffect } from 'react';
import CardBox from '../common/CardBox';
import { UserContext } from '../../providers/UserProvider';
import ActivitiesTable from './ActivitiesTable';

function AllActivity() {
  const { getUserActivities } = useContext(UserContext);

  useEffect(() => {
    getUserActivities();
  }, []);

  return (
    <CardBox>
      <ActivitiesTable title="Activities" />
    </CardBox>
  );
}

export default AllActivity;
