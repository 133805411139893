import { useContext, useEffect } from 'react';
import CardBox from '../common/CardBox';
import { UserContext } from '../../providers/UserProvider';
import DeletedMembersTable from './DeletedMembersTable';

function DeletedMembersList() {
  const { getDeletedClubMembers } = useContext(UserContext);

  useEffect(() => {
    getDeletedClubMembers();
  }, []);

  return (
    <CardBox>
      <DeletedMembersTable title="Deleted Members" />
    </CardBox>
  );
}

export default DeletedMembersList;
