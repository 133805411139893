import { useContext, useEffect, useState } from 'react';
import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import { useNavigate } from 'react-router-dom';
import InputText from '../formik/InputText';
import InputSelect from '../formik/InputSelect';
import SubmitButton from '../formik/SubmitButton';
import CardBox from '../common/CardBox';
import { getAuth } from 'firebase/auth';
import { UserContext } from '../../providers/UserProvider';
import { AppContext } from '../../providers/AppProvider';

function CompleteProfileForm() {
  const auth = getAuth();
  let navigate = useNavigate();
  const { signUp } = useContext(UserContext);
  const { clubs } = useContext(AppContext);
  const [years, setYears] = useState([]);

  const generateArrayOfYears = async () => {
    var max = new Date().getFullYear();
    var min = max - 100;
    var years = [];

    for (var i = max; i >= min; i--) {
      if (i <= 2010) {
        years.push(i);
      }
    }

    return years;
  };

  useEffect(() => {
    generateArrayOfYears().then(data => {
      setYears(data);
      console.log(data);
    });
  }, []);

  return (
    <CardBox bg="gray.50" borderRadius="2xl" p={5}>
      {auth.currentUser && (
        <Formik
          initialValues={{
            club_id: '',
            account_type_id: 1,
            name: auth.currentUser.displayName,
            email: auth.currentUser.email,
            year_of_birth: '',
            password: auth.currentUser.uid,
            showPassword: false,
          }}
          validationSchema={SignupSchema}
          onSubmit={(values, actions) => {
            values.club_id = parseInt(values.club_id);
            var data = {
              data: values,
            };
            console.log(values);
            signUp(data);
          }}
        >
          {({ isSubmitting }) => (
            <Form>
              <InputText name="name" />
              <InputText name="email" type="email" />
              <InputSelect
                name="club_id"
                title="Select a Club"
                options={clubs}
              />
              {years && (
                <InputSelect
                  name="year_of_birth"
                  title="Year of birth"
                  options={years}
                />
              )}
              <SubmitButton isSubmitting={isSubmitting} />
            </Form>
          )}
        </Formik>
      )}
    </CardBox>
  );
}

const SignupSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
    .required('Required'),
  club_id: Yup.number('Required').required('Required'),
  year_of_birth: Yup.number().required('Required'),
  email: Yup.string().email('Invalid email').required('Required'),
});

export default CompleteProfileForm;
