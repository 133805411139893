import { Box, Grid, Container } from '@chakra-ui/react';
import DesktopNav from './DesktopNav';
import MobileNav from './MobileNav';
import useWindowSize from '../../../helper/useWindowSize';

function PublicLayout({ children }) {
  const size = useWindowSize();

  return (
    <Box>
      <Box>{size[0] <= 768 ? <MobileNav /> : <DesktopNav />}</Box>
      <Container maxW="container.xl">
        <Box>
          <Grid minH="100vh">{children}</Grid>
        </Box>
      </Container>
    </Box>
  );
}
export default PublicLayout;
