import React, { useContext, useEffect } from 'react';
import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import { useNavigate } from 'react-router-dom';
import InputText from '../formik/InputText';
import InputSelect from '../formik/InputSelect';
import CustomSelect from '../formik/CustomSelect';
import SubmitButton from '../formik/SubmitButton';
import CardBox from '../common/CardBox';
import { UserContext } from '../../providers/UserProvider';
import TableLoadingSkeleton from '../loading/TableLoadingSkeleton';
import { Text } from '@chakra-ui/react';

function PreloadPointsForm() {
  let navigate = useNavigate();
  const {
    activityTypes,
    activityCategories,
    getActivityCategories,
    getActivities,
    setActivities,
    preloadPoints,
    getActivityTypes,
    clubMembers,
    loadingMember,
    getClubMembers,
  } = useContext(UserContext);

  useEffect(() => {
    getActivityCategories();
    if (clubMembers.length === 0) {
      getClubMembers();
    }
    if (activityTypes.length === 0) {
      getActivityTypes();
    }
  }, []);

  return (
    <CardBox bg="gray.50" borderRadius="2xl" p={5}>
      <Text as="b" fontSize="1rem" pr={1}>
        Preload Points
      </Text>

      {loadingMember ? (
        <TableLoadingSkeleton />
      ) : (
        <Formik
          initialValues={{
            user_id: '',
            activity_category_id: '',
            points: '',
            comments: '',
          }}
          validationSchema={SignupSchema}
          onSubmit={(values, actions) => {
            console.log(values);
            preloadPoints(values);
            setTimeout(() => {
              actions.setSubmitting(false);
              actions.resetForm();
            }, 1000);
          }}
        >
          {({ isSubmitting }) => (
            <Form>
              <InputSelect
                name="user_id"
                title="Club Member"
                options={clubMembers}
                onChange={e => {
                  if (e.target.value) {
                    getActivities(e.target.value);
                  } else {
                    setActivities([]);
                  }
                }}
              />
              <InputSelect
                name="activity_category_id"
                title="Activity Category"
                options={activityCategories}
              />
              <InputText name="points" title="Points" type="number" />
              <InputText name="comments" title="Comment (Optional)" />
              <SubmitButton isSubmitting={isSubmitting} />
            </Form>
          )}
        </Formik>
      )}
    </CardBox>
  );
}

const SignupSchema = Yup.object().shape({
  user_id: Yup.string().required('Required'),
  points: Yup.number().required('Required'),
});

export default PreloadPointsForm;
