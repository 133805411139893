import { useContext, useEffect } from 'react';
import { Box, SimpleGrid } from '@chakra-ui/react';
import ClubMembersList from '../../components/club/ClubMembersList';
import { UserContext } from '../../providers/UserProvider';

function ClubMembers() {
  const { user, clubMembers, getClubMembers } = useContext(UserContext);

  useEffect(() => {
    if (clubMembers.length === 0) {
      getClubMembers();
    }
  }, []);
  return (
    <Box>
      <SimpleGrid columns={1} spacing="24px">
        <ClubMembersList />
      </SimpleGrid>
    </Box>
  );
}

export default ClubMembers;
