import { useContext, useEffect } from 'react';
import CardBox from '../common/CardBox';
// import { UserContext } from '../../providers/UserProvider';
import ClubMembersTable from './ClubMembersTable';

function ClubMembersList() {
  //   const { getUserActivities } = useContext(UserContext);

  useEffect(() => {
    // getUserActivities();
  }, []);

  return (
    <CardBox>
      <ClubMembersTable title="Members" />
    </CardBox>
  );
}

export default ClubMembersList;
