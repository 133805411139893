import { useContext, useEffect } from 'react';
import { Box, Text, Center, Image, Tooltip } from '@chakra-ui/react';
import { signInWithGoogle, signInWithFacebook } from '../../providers/firebase';

import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { useNavigate, useLocation } from 'react-router-dom';
import IMAGE_ASSETS from '../../assets/image-list';
import { UserContext } from '../../providers/UserProvider';

function SocialLoginButtons() {
  const { signIn } = useContext(UserContext);
  let navigate = useNavigate();
  const location = useLocation();
  const auth = getAuth();

  const _redirect = () => {
    if (auth.currentUser) {
      console.log('signInWithGoogle', auth.currentUser);
      if (location.pathname === '/sign-up') {
        navigate('/complete-profile');
      } else if (location.pathname === '/sign-in') {
        const { email, uid } = auth.currentUser;
        let data = {
          email: email,
          password: uid,
        };
        signIn(data);
      }
    }
  };

  useEffect(() => {
    onAuthStateChanged(auth, user => {
      if (user) {
        _redirect();
      }
    });
  }, []);

  return (
    <Box>
      <Center m="10px">
        <Text>or continue with</Text>
      </Center>

      <Box>
        <Center>
          <Tooltip label="Sign up using Facebook">
            <Image
              boxSize="80px"
              style={{ cursor: 'pointer' }}
              src={IMAGE_ASSETS.src.facebookIcon}
              alt="Facebook Logo"
              onClick={() => {
                var _res = signInWithFacebook();
              }}
            />
          </Tooltip>
          <Tooltip label="Sign up using Google">
            <Image
              boxSize="80px"
              style={{ cursor: 'pointer' }}
              src={IMAGE_ASSETS.src.googleIcon}
              alt="Google Logo"
              onClick={async () => {
                var _res = await signInWithGoogle();
              }}
            />
          </Tooltip>
        </Center>
      </Box>
    </Box>
  );
}

export default SocialLoginButtons;
