import { useState, useContext, useEffect } from 'react';
import { HStack, VStack, Button, Box } from '@chakra-ui/react';
import LeaderboardTable from './LeaderboardTable';
import { UserContext } from '../../providers/UserProvider';

function LeaderboardTopButtons() {
  const { getLeaderboard } = useContext(UserContext);
  const [filterLeaderboard, setfilterLeaderboard] = useState({
    main: 1,
    sub: 1,
  });

  useEffect(() => {
    getLeaderboard(filterLeaderboard.sub);
  }, []);

  return (
    <Box>
      <VStack>
        {/* <HStack>
        {options.map(value => {
          return (
            <Button
              key={value.id}
              variant={
                filterLeaderboard.main === value.id ? 'solid' : 'outline'
              }
              colorScheme={'green'}
              onClick={() => {
                setfilterLeaderboard({
                  ...filterLeaderboard,
                  main: value.id,
                });
              }}
            >
              {value.name}
            </Button>
          );
        })}
      </HStack> */}
        <HStack>
          {options2.map(value => {
            return (
              <Button
                key={value.id}
                variant={
                  filterLeaderboard.sub === value.id ? 'solid' : 'outline'
                }
                colorScheme={'cyan'}
                color={filterLeaderboard.sub === value.id ? 'white' : 'gray'}
                onClick={() => {
                  getLeaderboard(value.id);
                  setfilterLeaderboard({
                    ...filterLeaderboard,
                    sub: value.id,
                  });
                }}
              >
                {value.name}
              </Button>
            );
          })}
        </HStack>
      </VStack>

      <LeaderboardTable
        title={`${options2[filterLeaderboard.sub - 1].name} Rank`}
        main={filterLeaderboard.main}
        sub={filterLeaderboard.sub}
      />
    </Box>
  );
}

export default LeaderboardTopButtons;

const options = [
  {
    id: 1,
    name: 'Branch',
  },
  {
    id: 2,
    name: 'State',
  },
  {
    id: 3,
    name: 'National',
  },
];
const options2 = [
  {
    id: 1,
    name: 'Individual',
  },
  {
    id: 2,
    name: 'Club',
  },
  {
    id: 3,
    name: 'Branch',
  },
  {
    id: 4,
    name: 'State',
  },
];
