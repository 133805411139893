import React, { useContext, useEffect } from 'react';
import * as Yup from 'yup';
import { Formik, Form, Field } from 'formik';
import { Text } from '@chakra-ui/react';
import { useNavigate, useLocation } from 'react-router-dom';
import InputText from '../formik/InputText';
import InputSelect from '../formik/InputSelect';
import CustomSelect from '../formik/CustomSelect';
import SubmitButton from '../formik/SubmitButton';
import CardBox from '../common/CardBox';
import { UserContext } from '../../providers/UserProvider';
import moment from 'moment';

function LogNewActivity() {
  let navigate = useNavigate();
  const { state } = useLocation();
  const {
    activityTypes,
    activities,
    getActivities,
    setActivities,
    logNewActivity,
    user,
  } = useContext(UserContext);

  useEffect(() => {
    console.log('user', user);
    console.log('state', state);
    if (state) {
      getActivities(state.data.activity_type_id);
    }
  }, []);

  return (
    <CardBox bg="gray.50" borderRadius="2xl" p={5}>
      <Formik
        initialValues={{
          supervisorSignOff: false,
          is_auto_approved: user.data.with_auto_approve_option,
          supervisor_name: '',
          activity_id: state ? state.data.activity_id : '',
          activity_date: moment().format('Y-MM-DD'),
          comment: '',
        }}
        validationSchema={SignupSchema}
        onSubmit={(values, actions) => {
          console.log(values);
          logNewActivity(values);
          setTimeout(() => {
            actions.setSubmitting(false);
            actions.resetForm();
          }, 1000);
        }}
      >
        {({ isSubmitting, values }) => (
          <Form>
            <CustomSelect
              name="activity_type"
              title={state ? state.data.activity_type_name : 'Activity Type'}
              options={activityTypes}
              onChange={e => {
                if (e.target.value) {
                  getActivities(e.target.value);
                } else {
                  setActivities([]);
                }
              }}
            />
            <InputSelect
              name="activity_id"
              title="Choose Activity"
              options={activities}
            />
            <InputText name="activity_date" title="Activity Date" type="date" />
            <InputText name="comment" title="Comment (Optional)" />
            {user.data.with_auto_approve_option === true && (
              <Text>
                <Field type="checkbox" name="supervisorSignOff" /> I have gotten
                my supervisor's signoff
              </Text>
            )}

            {values.supervisorSignOff === true && (
              <InputText
                name="supervisor_name"
                title="Supervisor Name"
                type="Text"
              />
            )}

            <SubmitButton isSubmitting={isSubmitting} />
          </Form>
        )}
      </Formik>
    </CardBox>
  );
}

const SignupSchema = Yup.object().shape({
  activity_id: Yup.string().required('Required'),
  activity_date: Yup.string().required('Required'),
});

export default LogNewActivity;
