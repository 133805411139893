import React, { useContext, useEffect, useState } from 'react';
import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import { useNavigate } from 'react-router-dom';
import InputText from '../formik/InputText';
import InputSelect from '../formik/InputSelect';
import CustomSelect from '../formik/CustomSelect';
import SubmitButton from '../formik/SubmitButton';
import CardBox from '../common/CardBox';
import { UserContext } from '../../providers/UserProvider';

function UpdateProfileForm() {
  let navigate = useNavigate();
  const { user, updateProfile } = useContext(UserContext);
  const [years, setYears] = useState([]);

  const generateArrayOfYears = async () => {
    var max = new Date().getFullYear();
    var min = max - 100;
    var years = [];

    for (var i = max; i >= min; i--) {
      if (i <= 2010) {
        years.push(i);
      }
    }

    return years;
  };

  useEffect(async () => {
    generateArrayOfYears().then(data => {
      setYears(data);
      console.log(data);
    });
  }, []);

  return (
    <CardBox bg="gray.50" borderRadius="2xl" p={5}>
      <Formik
        initialValues={{
          name: user.data.name,
          email: user.data.email,
          year_of_birth: user.data.year_of_birth,
        }}
        validationSchema={SignupSchema}
        onSubmit={(values, actions) => {
          console.log(values);
          setTimeout(() => {
            actions.setSubmitting(false);
            actions.resetForm();
            updateProfile(values);
          }, 1000);
        }}
      >
        {({ isSubmitting }) => (
          <Form>
            <InputText name="name" />
            <InputText name="email" />
            {years && (
              <InputSelect
                name="year_of_birth"
                title="Year of birth"
                options={years}
              />
            )}
            <SubmitButton isSubmitting={isSubmitting} title="Update Profile" />
          </Form>
        )}
      </Formik>
    </CardBox>
  );
}

const SignupSchema = Yup.object().shape({
  name: Yup.string().required('Required'),
  email: Yup.string().email('Invalid email').required('Required'),
});

export default UpdateProfileForm;
