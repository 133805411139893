import { Box, SimpleGrid } from '@chakra-ui/react';
import ClubActivitiesList from '../../components/club/ClubActivitiesList';

function ClubActivitiesPage() {
  return (
    <Box>
      <SimpleGrid columns={1} spacing="24px">
        <ClubActivitiesList />
      </SimpleGrid>
    </Box>
  );
}

export default ClubActivitiesPage;
