import React from 'react';
import { Box, SimpleGrid, Stack } from '@chakra-ui/react';
import DeletedActivities from '../../components/activity/DeletedActivities';

function DeletedActivitiesPage() {
  return (
    <Box>
      <DeletedActivities />
    </Box>
  );
}

export default DeletedActivitiesPage;
