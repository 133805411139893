import {
  chakra,
  Box,
  Text,
  Button,
  Stack,
  Icon,
  Image,
  Link,
  theme,
} from '@chakra-ui/react';
import IMAGE_ASSETS from '../../assets/image-list';
import { useNavigate } from 'react-router-dom';

export default function LandingHero() {
  let navigate = useNavigate();
  return (
    <Box px={8} py={24} mx="auto">
      <Box
        w={{
          base: 'full',
          md: 11 / 12,
          xl: 9 / 12,
        }}
        mx="auto"
        textAlign={{
          base: 'left',
          md: 'center',
        }}
      >
        <chakra.h1
          mb={6}
          fontSize={{
            base: '4xl',
            md: '6xl',
          }}
          fontWeight="bold"
          lineHeight="none"
          letterSpacing={{
            base: 'normal',
            md: 'tight',
          }}
          color="gray.900"
          _dark={{
            color: 'gray.100',
          }}
        >
          Welcome to the
          <Text
            display={{
              base: 'block',
              lg: 'inline',
            }}
            w="full"
            bgClip="text"
            bgGradient="linear(to-r, green.400,purple.500)"
            fontWeight="extrabold"
          >
            Youth Involvement Program
          </Text>{' '}
          Website
        </chakra.h1>
        <chakra.p
          px={{
            base: 0,
            lg: 24,
          }}
          mb={6}
          fontSize={{
            base: 'lg',
            md: 'xl',
          }}
          color="gray.600"
          _dark={{
            color: 'gray.300',
          }}
        >
          The Youth Involvement Program was created to help with the retention
          of young keen members who could follow a pathway of qualifications to
          gain throughout the season. This program was designed to provide a
          plan for young lifesaving members to follow in achieving little goals
          each week. The end goal was to try and reach the Gold Level for the
          Youth Involvement Program.
        </chakra.p>
        <chakra.p
          px={{
            base: 0,
            lg: 24,
          }}
          mb={6}
          fontSize={{
            base: 'lg',
            md: 'xl',
          }}
          color="gray.600"
          _dark={{
            color: 'gray.300',
          }}
        >
          The web app has been designed to remove the previous paper based
          constraints and assist in creating a fun competition with your club
          team mates and across the clubs within the Branch and State. Become
          the leading club for your age group while achieving tasks today.
        </chakra.p>
        <Stack
          direction={{
            base: 'column',
            sm: 'row',
          }}
          mb={{
            base: 4,
            md: 8,
          }}
          spacing={2}
          justifyContent={{
            sm: 'left',
            md: 'center',
          }}
        >
          {/* <Image
            boxSize="70px"
            objectFit="fit"
            rounded="lg"
            src={IMAGE_ASSETS.src.logo}
            alt="YIP LOGO"
          /> */}
          <Button
            as="a"
            bgColor={theme.colors.cyan[600]}
            color="white"
            variant="solid"
            display="inline-flex"
            alignItems="center"
            justifyContent="center"
            w={{
              base: 'full',
              sm: 'auto',
            }}
            mb={{
              base: 2,
              sm: 0,
            }}
            size="lg"
            cursor="pointer"
            onClick={() => {
              navigate('/sign-in');
            }}
          >
            Sign In
            <Icon boxSize={4} ml={1} viewBox="0 0 20 20" fill="currentColor">
              <path
                fillRule="evenodd"
                d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                clipRule="evenodd"
              />
            </Icon>
          </Button>
          <Button
            as="a"
            colorScheme="gray"
            display="inline-flex"
            alignItems="center"
            justifyContent="center"
            w={{
              base: 'full',
              sm: 'auto',
            }}
            mb={{
              base: 2,
              sm: 0,
            }}
            size="lg"
            cursor="pointer"
            onClick={() => {
              navigate('/sign-up');
            }}
          >
            <Text as="u">Sign Up</Text>
            <Icon boxSize={4} ml={1} viewBox="0 0 20 20" fill="currentColor">
              <path
                fillRule="evenodd"
                d="M6.672 1.911a1 1 0 10-1.932.518l.259.966a1 1 0 001.932-.518l-.26-.966zM2.429 4.74a1 1 0 10-.517 1.932l.966.259a1 1 0 00.517-1.932l-.966-.26zm8.814-.569a1 1 0 00-1.415-1.414l-.707.707a1 1 0 101.415 1.415l.707-.708zm-7.071 7.072l.707-.707A1 1 0 003.465 9.12l-.708.707a1 1 0 001.415 1.415zm3.2-5.171a1 1 0 00-1.3 1.3l4 10a1 1 0 001.823.075l1.38-2.759 3.018 3.02a1 1 0 001.414-1.415l-3.019-3.02 2.76-1.379a1 1 0 00-.076-1.822l-10-4z"
                clipRule="evenodd"
              />
            </Icon>
          </Button>
        </Stack>
      </Box>
    </Box>
  );
}
