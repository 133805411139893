import React, { useContext } from 'react';
import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import { Link } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import InputText from '../formik/InputText';
import SubmitButton from '../formik/SubmitButton';
import SocialLoginButtons from './SocialLoginButtons';
import CardBox from '../common/CardBox';
import { UserContext } from '../../providers/UserProvider';

function SignInForm() {
  let navigate = useNavigate();
  const { signIn } = useContext(UserContext);

  return (
    <CardBox>
      <Formik
        initialValues={{
          email: '',
          password: '',
          showPassword: false,
        }}
        validationSchema={SignupSchema}
        onSubmit={(values, actions) => {
          setTimeout(() => {
            console.log(values);
            actions.setSubmitting(false);
            signIn(values);
          }, 1500);

          values.club_id = parseInt(values.club_id);
        }}
      >
        {({ isSubmitting }) => (
          <Form>
            <InputText name="email" type="email" />
            <InputText name="password" type="password" />
            <Link color="cyan.600" onClick={() => navigate('/forgot-password')}>
              Forgot Password?
            </Link>
            <SubmitButton isSubmitting={isSubmitting} />
          </Form>
        )}
      </Formik>
      <SocialLoginButtons />
    </CardBox>
  );
}

const SignupSchema = Yup.object().shape({
  password: Yup.string().min(3, 'Password is too short').required('Required'),
  email: Yup.string().email('Invalid email').required('Required'),
});

export default SignInForm;
