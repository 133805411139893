import React from 'react';
import { Box, Heading, Text } from '@chakra-ui/react';

function TextHeader({ title, subtitle }) {
  return (
    <Box>
      <Heading as="h1">{title}</Heading>
      <Text mb={5}>{subtitle}</Text>
    </Box>
  );
}

export default TextHeader;
