import React from 'react';
import { Box, SimpleGrid } from '@chakra-ui/react';
import EditMemberInfoForm from '../../components/forms/EditMemberInfoForm';

function EditMemberPage() {
  return (
    <Box>
      <SimpleGrid columns={1} spacing="24px">
        <EditMemberInfoForm />
      </SimpleGrid>
    </Box>
  );
}

export default EditMemberPage;
