import { useNavigate } from 'react-router-dom';
import {
  Button,
  Spacer,
  Image,
  HStack,
  Container,
  theme,
} from '@chakra-ui/react';
import IMAGE_ASSETS from '../../../assets/image-list';

function DesktopNav() {
  let navigate = useNavigate();
  return (
    <Container maxW="container.xl">
      <HStack pt={5} pb={5}>
        <Image
          boxSize="70px"
          objectFit="fit"
          src={IMAGE_ASSETS.src.logo}
          alt="YIP LOGO"
          onClick={() => {
            navigate('/');
          }}
          style={{ cursor: 'pointer' }}
        />
        <Spacer />

        <Button
          onClick={() => {
            navigate('/sign-in');
          }}
          bgColor={theme.colors.cyan[600]}
          color="white"
          variant="solid"
          m={4}
        >
          Sign In
        </Button>
        <Button
          onClick={() => {
            navigate('/sign-up');
          }}
          colorScheme="black"
          variant="outline"
          m={4}
        >
          Sign Up
        </Button>
      </HStack>
    </Container>
  );
}
export default DesktopNav;
