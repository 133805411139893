import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Button,
  Checkbox,
  Badge,
  Text,
  HStack,
  Grid,
  VStack,
  Box,
  Table,
  Thead,
  Tbody,
  Tooltip,
  IconButton,
  Divider,
  Tr,
  Th,
  Td,
  TableCaption,
  Spacer,
  Flex,
  Center,
} from '@chakra-ui/react';
import CardBox from '../common/CardBox';
import { UserContext } from '../../providers/UserProvider';
import TableLoadingSkeleton from '../loading/TableLoadingSkeleton';
import useWindowSize from '../../helper/useWindowSize';
import moment from 'moment';
import { FaTrash } from 'react-icons/fa';
import { MdRefresh } from 'react-icons/md';
function PendingInvitationsTable({ title, max = 0 }) {
  let navigate = useNavigate();
  const size = useWindowSize();
  const {
    loadingUsers,
    pendingInvites,
    editInviteMember,
    deleteInvitations,
    getPendingInvites,
  } = useContext(UserContext);
  const [checkedItems, setCheckedItems] = useState([]);
  const [selectedIds, setSelectedIds] = useState([]);
  const [allIds, setAllIds] = useState([]);
  const [count, setCount] = useState(0);
  const [allChecked, setAllChecked] = useState(false);
  const [isIndeterminate, setIsIndeterminate] = useState(false);

  const unselectAll = () => {
    setAllChecked(false);
    setIsIndeterminate(false);
    setCheckedItems([]);
    var _ids = [];
    for (let index = 0; index != pendingInvites.length; index++) {
      _ids[index] = pendingInvites[index].id;
      setAllIds([..._ids]);
      setCheckedItems(oldArray => [...oldArray, false]);
    }
  };

  const handleAllChecked = event => {
    setAllChecked(event.target.checked);
    let _items = checkedItems;
    _items.forEach((_item, index) => {
      _items[index] = event.target.checked;
    });
    if (event.target.checked) {
      setSelectedIds([...allIds]);
    } else {
      setSelectedIds([]);
    }
    setCheckedItems([..._items]);
  };

  const handleCheckFieldElement = event => {
    var count = 0;
    let _items = checkedItems;

    if (event.target.checked) {
      setSelectedIds([...selectedIds, event.target.value]);
    } else {
      var _ids = selectedIds.filter(_id => _id !== event.target.value);
      setSelectedIds([..._ids]);
    }

    _items.forEach((_item, index) => {
      if (index == event.target.id) {
        _items[index] = event.target.checked;
      }
      if (_item == true) {
        count++;
      }
    });

    if (count > 1 && count < checkedItems.length) {
      setIsIndeterminate(true);
    }

    if (count == checkedItems.length) {
      setIsIndeterminate(false);
      setAllChecked(true);
    }

    setCheckedItems([..._items]);
  };

  const editInvitation = invitationInfoId => {
    localStorage.setItem('invitationInfoId', JSON.stringify(invitationInfoId));
    navigate('/club/invite/edit');
  };

  useEffect(() => {
    getPendingInvites();
  }, []);

  useEffect(() => {
    unselectAll();
  }, [pendingInvites]);

  useEffect(() => {
    var count = 0;
    let _items = checkedItems;

    _items.forEach(_item => {
      if (_item == true) {
        count++;
      }
    });

    if (count > 0 && count < checkedItems.length) {
      setIsIndeterminate(true);
    }

    if (count == checkedItems.length) {
      setIsIndeterminate(false);
      setAllChecked(true);
    }

    if (count == 0) {
      setIsIndeterminate(false);
      setAllChecked(false);
    }
    setCount(count);
  }, [checkedItems]);

  if (size[0] <= 768) {
    return (
      <CardBox>
        <Box>
          <HStack mb="15px">
            <Checkbox
              isChecked={allChecked}
              isIndeterminate={isIndeterminate}
              onChange={handleAllChecked}
            />
            <Divider orientation="vertical" />
            <Text as="b" fontSize="1rem">
              Pending Invitations
            </Text>
            <Tooltip label={'Refresh List'}>
              <IconButton
                bgColor="cyan.600"
                color="white"
                aria-label={'Refresh List'}
                size="sm"
                ml={2}
                onClick={() => {
                  getPendingInvites();
                }}
                icon={<MdRefresh />}
              />
            </Tooltip>
          </HStack>

          {loadingUsers ? <TableLoadingSkeleton /> : null}

          {pendingInvites.map((member, index) => {
            return (
              <Box mb="15px">
                <Divider />
                <HStack>
                  <Box>
                    <Checkbox
                      id={index}
                      value={member.id}
                      isChecked={checkedItems[index]}
                      onChange={handleCheckFieldElement}
                    />
                  </Box>
                  <Divider orientation="vertical" />
                  <Box>
                    <Text fontSize="14px">
                      <Text as="span" fontWeight="bold" isTruncated>
                        Name:
                      </Text>{' '}
                      {member.name}
                    </Text>
                    <Text fontSize="14px">
                      <Text as="span" fontWeight="bold">
                        Email:
                      </Text>{' '}
                      {member.email}
                    </Text>
                    <Text fontSize="14px">
                      <Text as="span" fontWeight="bold">
                        Status:
                      </Text>{' '}
                      {member.status}
                    </Text>
                    <Box></Box>
                  </Box>
                </HStack>
              </Box>
            );
          })}

          <Button
            w="100%"
            colorScheme={'red'}
            disabled={count > 0 ? false : true}
            onClick={() => {
              deleteInvitations(selectedIds);
            }}
          >
            Delete Selected {count > 0 ? `(${count})` : null}
          </Button>
        </Box>
      </CardBox>
    );
  } else {
    return (
      <CardBox>
        <Table
          variant="simple"
          size={size[0] <= 768 ? 'sm' : 'md'}
          fontSize="small"
        >
          <Thead>
            <Tr>
              <Th colSpan={3} pb={5}>
                <Text as="b" fontSize="1rem">
                  Pending Invitations
                </Text>
              </Th>
              <Th colSpan={2}>
                <Flex alignItems={'right'}>
                  <Spacer />
                  <Tooltip label={'Refresh List'}>
                    <IconButton
                      bgColor="cyan.600"
                      color="white"
                      aria-label={'Refresh List'}
                      size="sm"
                      ml={2}
                      onClick={() => {
                        getPendingInvites();
                      }}
                      icon={<MdRefresh />}
                    />
                  </Tooltip>
                  <Tooltip label={'Delete Invitations'}>
                    <IconButton
                      colorScheme={'red'}
                      aria-label={'Delete Invitations'}
                      disabled={count > 0 ? false : true}
                      size="sm"
                      ml={2}
                      onClick={() => {
                        deleteInvitations(selectedIds);
                      }}
                      icon={<FaTrash />}
                    />
                  </Tooltip>
                </Flex>
              </Th>
            </Tr>
            <Tr>
              <Th>
                <Checkbox
                  isChecked={allChecked}
                  isIndeterminate={isIndeterminate}
                  onChange={handleAllChecked}
                />
              </Th>
              <Th>Name</Th>
              <Th>Email</Th>
              <Th>Role</Th>
            </Tr>
          </Thead>
          <Tbody>
            {loadingUsers ? (
              <Tr>
                <Td colSpan={5}>
                  <TableLoadingSkeleton />
                </Td>
              </Tr>
            ) : null}
            {loadingUsers === false && pendingInvites.length === 0 ? (
              <Tr>
                <Td colSpan={5}>
                  <Center>No {title}</Center>
                </Td>
              </Tr>
            ) : null}
            {loadingUsers === false &&
              pendingInvites.map((member, index) => {
                return (
                  <Tr
                    key={'row' + index}
                    style={{ cursor: 'pointer' }}
                    _hover={{ bgColor: 'cyan.400' }}
                  >
                    <Td>
                      <Checkbox
                        id={index}
                        value={member.id}
                        isChecked={checkedItems[index]}
                        onChange={handleCheckFieldElement}
                      />
                    </Td>
                    <Td onClick={() => editInvitation(member.id)}>
                      {member.name}
                    </Td>

                    <Td onClick={() => editInvitation(member.id)}>
                      {member.email}
                    </Td>
                    <Td
                      fontWeight="bold"
                      onClick={() => editInvitation(member.id)}
                    >
                      {member.account_type_name}
                    </Td>
                  </Tr>
                );
              })}
          </Tbody>
        </Table>
      </CardBox>
    );
  }
}

export default PendingInvitationsTable;
