import React, { useContext, useEffect, useState } from 'react';
import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import { useNavigate } from 'react-router-dom';
import InputText from '../formik/InputText';
import InputSelect from '../formik/InputSelect';
import CustomSelect from '../formik/CustomSelect';
import SubmitButton from '../formik/SubmitButton';
import CardBox from '../common/CardBox';
import { UserContext } from '../../providers/UserProvider';

function EditMemberInfoForm() {
  let navigate = useNavigate();
  const {
    user,
    updateMemberInfo,
    getClubMembers,
    getAccountTypes,
    getBranches,
    getClubs,
    getStates,
    states,
    clubs,
    branches,
    accountTypes,
  } = useContext(UserContext);
  const [years, setYears] = useState([]);
  const [memberInfo, setMemberInfo] = useState(null);

  const generateArrayOfYears = async () => {
    var max = new Date().getFullYear();
    var min = max - 100;
    var years = [];

    for (var i = max; i >= min; i--) {
      if (i <= 2010) {
        years.push(i);
      }
    }

    return years;
  };

  useEffect(() => {
    if (user.data.is_admin === false) {
      navigate('/dashboard');
    }
    getAccountTypes();
    getBranches();
    getClubs();
    getStates();
    generateArrayOfYears().then(data => {
      setYears(data);
      console.log(data);
    });
    var _data = JSON.parse(localStorage.getItem('editMemberInfo'));
    setMemberInfo(_data);
  }, []);

  return (
    <CardBox bg="gray.50" borderRadius="2xl" p={5}>
      {memberInfo && (
        <Formik
          initialValues={{
            user_id: memberInfo.id,
            account_type_id: memberInfo.account_type_id,
            club_id: memberInfo.club_id ?? null,
            club_branch_id: memberInfo.club_branch_id ?? null,
            club_state_id: memberInfo.club_state_id ?? null,
            name: memberInfo.name,
            email: memberInfo.email,
            year_of_birth: memberInfo.year_of_birth,
          }}
          validationSchema={SignupSchema}
          onSubmit={(values, actions) => {
            console.log(values);
            updateMemberInfo(values);
            setTimeout(() => {
              actions.setSubmitting(false);
              getClubMembers();
              navigate('/club/members');
              // actions.resetForm();
            }, 1000);
          }}
        >
          {({ isSubmitting, values }) => (
            <Form>
              <InputSelect
                name="account_type_id"
                title="Member Type"
                options={accountTypes}
              />

              {values.account_type_id == 1 || values.account_type_id == 2 ? (
                <InputSelect name="club_id" title="Club" options={clubs} />
              ) : null}

              {values.account_type_id == 3 ? (
                <InputSelect
                  name="club_branch_id"
                  title="Branch"
                  options={branches}
                />
              ) : null}

              {values.account_type_id == 4 ? (
                <InputSelect
                  name="club_state_id"
                  title="State"
                  options={states}
                />
              ) : null}
              <InputText name="name" />
              <InputText name="email" type="email" />
              {years && (
                <InputSelect
                  name="year_of_birth"
                  title="Year of birth"
                  options={years}
                />
              )}
              <SubmitButton isSubmitting={isSubmitting} />
            </Form>
          )}
        </Formik>
      )}
    </CardBox>
  );
}

const SignupSchema = Yup.object().shape({
  account_type_id: Yup.string().required('Required'),
  name: Yup.string()
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
    .required('Required'),
  // year_of_birth: Yup.number().required('Required'),
  year_of_birth: Yup.number().required('Required'),
  email: Yup.string().email('Invalid email').required('Required'),
});

const userTypes = [
  {
    id: false,
    name: 'Member',
  },
  {
    id: true,
    name: 'Admin',
  },
];

export default EditMemberInfoForm;
