import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Button,
  Checkbox,
  Badge,
  Text,
  HStack,
  Grid,
  VStack,
  Box,
  Table,
  Thead,
  Tbody,
  Tooltip,
  IconButton,
  Divider,
  Tr,
  Th,
  Td,
  TableCaption,
  Spacer,
  Flex,
  Center,
} from '@chakra-ui/react';
import { UserContext } from '../../providers/UserProvider';
import ActivityButton from './ActivityButton';
import TableLoadingSkeleton from '../loading/TableLoadingSkeleton';
import useWindowSize from '../../helper/useWindowSize';
import moment from 'moment';
import { FaTrash, FaRecycle } from 'react-icons/fa';
import { MdRefresh } from 'react-icons/md';
function DeletedActivitiesTable({ title, max = 0 }) {
  let navigate = useNavigate();
  const size = useWindowSize();
  const {
    user,
    loading,
    deletedActivities,
    restoreActivities,
    getDeletedActivities,
  } = useContext(UserContext);
  const [checkedItems, setCheckedItems] = useState([]);
  const [selectedIds, setSelectedIds] = useState([]);
  const [allIds, setAllIds] = useState([]);
  const [count, setCount] = useState(0);
  const [allChecked, setAllChecked] = useState(false);
  const [isIndeterminate, setIsIndeterminate] = useState(false);

  const unselectAll = () => {
    setAllChecked(false);
    setIsIndeterminate(false);
    setCheckedItems([]);
    var _ids = [];
    for (let index = 0; index != deletedActivities.length; index++) {
      _ids[index] = deletedActivities[index].id;
      setAllIds([..._ids]);
      setCheckedItems(oldArray => [...oldArray, false]);
    }
  };

  const handleAllChecked = event => {
    setAllChecked(event.target.checked);
    let _items = checkedItems;
    _items.forEach((_item, index) => {
      _items[index] = event.target.checked;
    });
    if (event.target.checked) {
      setSelectedIds([...allIds]);
    } else {
      setSelectedIds([]);
    }
    setCheckedItems([..._items]);
  };

  const handleCheckFieldElement = event => {
    var count = 0;
    let _items = checkedItems;

    if (event.target.checked) {
      setSelectedIds([...selectedIds, event.target.value]);
    } else {
      var _ids = selectedIds.filter(_id => _id !== event.target.value);
      setSelectedIds([..._ids]);
    }

    _items.forEach((_item, index) => {
      if (index == event.target.id) {
        _items[index] = event.target.checked;
      }
      if (_item == true) {
        count++;
      }
    });

    if (count > 1 && count < checkedItems.length) {
      setIsIndeterminate(true);
    }

    if (count == checkedItems.length) {
      setIsIndeterminate(false);
      setAllChecked(true);
    }

    setCheckedItems([..._items]);
  };

  const editActivity = activity => {
    console.log(activity);
    localStorage.setItem('editActivity', JSON.stringify(activity));
    navigate('/activities/edit');
  };

  useEffect(() => {
    unselectAll();
  }, [deletedActivities]);

  useEffect(() => {
    var count = 0;
    let _items = checkedItems;

    _items.forEach(_item => {
      if (_item == true) {
        count++;
      }
    });

    if (count > 0 && count < checkedItems.length) {
      setIsIndeterminate(true);
    }

    if (count == checkedItems.length) {
      setIsIndeterminate(false);
      setAllChecked(true);
    }

    if (count == 0) {
      setIsIndeterminate(false);
      setAllChecked(false);
    }
    setCount(count);
  }, [checkedItems]);

  if (size[0] <= 768) {
    return (
      <Box>
        <HStack mb="15px">
          <Checkbox
            isChecked={allChecked}
            isIndeterminate={isIndeterminate}
            onChange={handleAllChecked}
          />
          <Divider orientation="vertical" />
          <Text as="b" fontSize="1rem">
            {title}
          </Text>
          <Tooltip label={'Refresh List'}>
            <IconButton
              bgColor="cyan.600"
              color="white"
              aria-label={'Refresh List'}
              size="sm"
              ml={2}
              onClick={() => {
                getDeletedActivities();
              }}
              icon={<MdRefresh />}
            />
          </Tooltip>
          {user.data.is_admin ? (
            <Tooltip label={'Restore selected activities'}>
              <IconButton
                disabled={count > 0 ? false : true}
                bgColor="red.600"
                color="white"
                aria-label={'Restore selected activities'}
                size="sm"
                ml={2}
                onClick={() => {
                  restoreActivities(selectedIds);
                }}
                icon={<FaRecycle />}
              />
            </Tooltip>
          ) : null}
        </HStack>

        {loading ? <TableLoadingSkeleton /> : null}

        {deletedActivities.map((activity, index) => {
          return (
            <Box mb="15px">
              <Divider />
              <HStack>
                <Box>
                  <Checkbox
                    id={index}
                    value={activity.id}
                    isChecked={checkedItems[index]}
                    onChange={handleCheckFieldElement}
                  />
                </Box>
                <Divider orientation="vertical" />
                <Box onClick={() => editActivity(activity)}>
                  <Text fontSize="14px">
                    <Text as="span" fontWeight="bold" isTruncated>
                      Name:
                    </Text>{' '}
                    {activity.user_name}
                  </Text>
                  <Text fontSize="14px">
                    <Text as="span" fontWeight="bold">
                      Activity:
                    </Text>{' '}
                    {activity.activity_name}
                  </Text>
                  <Text fontSize="14px">
                    <Text as="span" fontWeight="bold">
                      Date:
                    </Text>{' '}
                    {activity.activity_date}
                  </Text>
                  <Text fontSize="14px">
                    <Text as="span" fontWeight="bold">
                      Points:
                    </Text>{' '}
                    {activity.credits}
                  </Text>
                  <Box></Box>
                </Box>
              </HStack>
            </Box>
          );
        })}

        {user.data.is_admin ? (
          <Button
            w="100%"
            bgColor="green.400"
            color="white"
            disabled={count > 0 ? false : true}
            onClick={() => {
              restoreActivities(selectedIds);
            }}
          >
            Restore Selected {count > 0 ? `(${count})` : null}
          </Button>
        ) : null}
      </Box>
    );
  } else {
    return (
      <Table
        variant="simple"
        size={size[0] <= 768 ? 'sm' : 'md'}
        fontSize="small"
      >
        {user.data.is_admin ? (
          <TableCaption>
            <Button
              w="100%"
              bgColor="green.400"
              color="white"
              disabled={count > 0 ? false : true}
              onClick={() => {
                restoreActivities(selectedIds);
              }}
            >
              Restore Selected {count > 0 ? `(${count})` : null}
            </Button>
          </TableCaption>
        ) : null}
        <Thead>
          <Tr>
            <Th colSpan={3} pb={5}>
              <Text as="b" fontSize="1rem">
                {title}
              </Text>
            </Th>
            <Th colSpan={2}>
              <Flex alignItems={'right'}>
                <Spacer />
                <Tooltip label={'Refresh List'}>
                  <IconButton
                    bgColor="cyan.600"
                    color="white"
                    aria-label={'Refresh List'}
                    size="sm"
                    ml={2}
                    onClick={() => {
                      getDeletedActivities();
                    }}
                    icon={<MdRefresh />}
                  />
                </Tooltip>
                {user.data.is_admin ? (
                  <Tooltip label={'Restore selected activities'}>
                    <IconButton
                      disabled={count > 0 ? false : true}
                      bgColor="green.400"
                      color="white"
                      aria-label={'Restore selected activities'}
                      size="sm"
                      ml={2}
                      onClick={() => {
                        restoreActivities(selectedIds);
                      }}
                      icon={<FaRecycle />}
                    />
                  </Tooltip>
                ) : null}
              </Flex>
            </Th>
          </Tr>
          <Tr>
            {user.data.is_admin ? (
              <Th>
                <Checkbox
                  isChecked={allChecked}
                  isIndeterminate={isIndeterminate}
                  onChange={handleAllChecked}
                />
              </Th>
            ) : null}
            <Th>Date</Th>
            <Th>Name</Th>
            <Th>Activity</Th>
            <Th isNumeric>Points</Th>
          </Tr>
        </Thead>
        <Tbody>
          {loading ? (
            <Tr>
              <Td colSpan={5}>
                <TableLoadingSkeleton />
              </Td>
            </Tr>
          ) : null}
          {loading === false && deletedActivities.length === 0 ? (
            <Tr>
              <Td colSpan={5}>
                <Center>No {title}</Center>
              </Td>
            </Tr>
          ) : null}
          {deletedActivities.map((activity, index) => {
            return (
              <Tr key={'row' + index}>
                {user.data.is_admin ? (
                  <Td>
                    <Checkbox
                      id={index}
                      value={activity.id}
                      isChecked={checkedItems[index]}
                      onChange={handleCheckFieldElement}
                    />
                  </Td>
                ) : null}
                <Td>{activity.activity_date}</Td>
                <Td>{activity.user_name}</Td>

                <Td>{activity.activity_name}</Td>
                <Td isNumeric fontWeight="bold">
                  {activity.credits}
                </Td>
              </Tr>
            );
          })}
        </Tbody>
      </Table>
    );
  }
}

export default DeletedActivitiesTable;
