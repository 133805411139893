import React from 'react';
import { Box, SimpleGrid } from '@chakra-ui/react';
import UpdateProfileForm from '../../components/forms/UpdateProfileForm';

function UpdateProfilePage() {
  return (
    <Box>
      <SimpleGrid columns={1} spacing="24px">
        <UpdateProfileForm />
      </SimpleGrid>
    </Box>
  );
}

export default UpdateProfilePage;
