import { useContext, useEffect } from 'react';
import CardBox from '../common/CardBox';
import { UserContext } from '../../providers/UserProvider';
import PendingActivitiesTable from './PendingActivitiesTable';

function PendingActivities() {
  const { getPendingActivities } = useContext(UserContext);

  useEffect(() => {
    getPendingActivities();
  }, []);

  return (
    <CardBox>
      <PendingActivitiesTable title="Pending Activities" />
    </CardBox>
  );
}

export default PendingActivities;
