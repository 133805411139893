import { useContext, useEffect } from 'react';
import CardBox from '../common/CardBox';
import { UserContext } from '../../providers/UserProvider';
import LeaderboardTopButtons from './LeaderboardTopButtons';

function AllLeaderboards() {
  useEffect(() => {
    // getUserActivities();
  }, []);

  return (
    <CardBox>
      <LeaderboardTopButtons />
    </CardBox>
  );
}

export default AllLeaderboards;
