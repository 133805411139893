import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Text,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuDivider,
  Box,
  Badge,
  Tooltip,
} from '@chakra-ui/react';
import { BellIcon } from '@chakra-ui/icons';
import { UserContext } from '../../../providers/UserProvider';
import moment from 'moment';

function NotificationsMenu() {
  let navigate = useNavigate();
  const { notifications } = useContext(UserContext);

  return (
    <Menu>
      <Tooltip label="Notifications">
        <MenuButton>
          {notifications.length <= 0 ? null : (
            <Badge fontSize="0.8em" colorScheme="red">
              {notifications.length}
            </Badge>
          )}
          <BellIcon boxSize={'1.5rem'} />
        </MenuButton>
      </Tooltip>
      <MenuList maxH={350} overflowY={'scroll'}>
        {notifications.length <= 0 ? (
          <MenuItem minH="48px" onClick={() => navigate('/notifications')}>
            <Text as="span" color="gray">
              No unread notifications
            </Text>
          </MenuItem>
        ) : null}
        {notifications.map((value, key) => (
          <MenuItem
            key={key}
            minH="48px"
            onClick={() => navigate('/notifications')}
          >
            <Box key={`notification-${key}`}>
              <Text as="b" fontSize="1rem">
                <Badge>{moment(value.created_at).fromNow()}</Badge>
              </Text>
              <Text fontSize="0.8rem">{value.message}</Text>
            </Box>
          </MenuItem>
        ))}

        <MenuDivider />
        <MenuItem
          minH="48px"
          color="cyan.600"
          justifyContent="center"
          onClick={() => navigate('/notifications')}
        >
          View all
        </MenuItem>
      </MenuList>
    </Menu>
  );
}
export default NotificationsMenu;
