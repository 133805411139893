import React, { useContext, useEffect, useState } from 'react';
import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import { Select, FormLabel, Text } from '@chakra-ui/react';
import InputText from '../formik/InputText';
import InputSelect from '../formik/InputSelect';
import SubmitButton from '../formik/SubmitButton';
import SocialLoginButtons from './SocialLoginButtons';
import CardBox from '../common/CardBox';
import { UserContext } from '../../providers/UserProvider';
import { AppContext } from '../../providers/AppProvider';

function SignUpForm() {
  const { signUp } = useContext(UserContext);
  const { clubs, getClubs, states, loading } = useContext(AppContext);
  const [years, setYears] = useState([]);
  const [selectedState, setSelectedState] = useState(null);

  const generateArrayOfYears = async () => {
    var max = new Date().getFullYear();
    var min = max - 100;
    var years = [];

    for (var i = max; i >= min; i--) {
      if (i <= 2010) {
        years.push(i);
      }
    }

    return years;
  };

  useEffect(() => {
    generateArrayOfYears().then(data => {
      setYears(data);
      console.log(data);
    });
  }, []);

  return (
    <CardBox>
      <Formik
        initialValues={{
          club_id: '',
          name: '',
          email: '',
          year_of_birth: '',
          password: '',
          account_type_id: 1,
          showPassword: false,
        }}
        validationSchema={SignupSchema}
        onSubmit={(values, actions) => {
          setTimeout(() => {
            console.log(values);
            actions.setSubmitting(false);
            values.club_id = parseInt(values.club_id);
            var data = {
              data: values,
            };
            signUp(data);
            //  actions.resetForm();
          }, 1000);
        }}
      >
        {({ isSubmitting, handleChange }) => (
          <Form>
            <InputText name="name" />
            <InputText name="email" type="email" />
            <InputText name="password" type="password" />
            <FormLabel mt={3}>Select State</FormLabel>
            <Select
              placeholder={`Select State`}
              onChange={e => {
                getClubs(e.target.value);
                setSelectedState(e.target.value);
              }}
            >
              {states &&
                states.map((value, key) => {
                  return (
                    <option key={key} value={value.id}>
                      {value.name}
                    </option>
                  );
                })}
            </Select>
            {loading && <Text>Loading Clubs...</Text>}
            <InputSelect
              name="club_id"
              title="Select a Club"
              options={clubs}
              disabled={
                selectedState == null && loading == false ? true : false
              }
            />
            {years && (
              <InputSelect
                name="year_of_birth"
                title="Year of birth"
                options={years}
              />
            )}
            <SubmitButton isSubmitting={isSubmitting} />
          </Form>
        )}
      </Formik>
      <SocialLoginButtons />
    </CardBox>
  );
}

const SignupSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
    .required('Required'),
  club_id: Yup.number('Required').required('Required'),
  year_of_birth: Yup.number().required('Required'),
  password: Yup.string().min(3, 'Password is too short').required('Required'),
  email: Yup.string().email('Invalid email').required('Required'),
});

export default SignUpForm;
