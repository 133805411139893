import { useContext, useMemo, useCallback, useEffect } from 'react';
import {
  Tooltip,
  Button,
  TableCaption,
  Box,
  IconButton,
  HStack,
  Badge,
  Link,
} from '@chakra-ui/react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { UserContext } from '../../providers/UserProvider';
import TableLoadingSkeleton from '../loading/TableLoadingSkeleton';
import useWindowSize from '../../helper/useWindowSize';
import InviteMemberButton from './InviteMemberButton';
import DataTable from '../common/DataTable';
import moment from 'moment';

import { FaPen, FaTrash, FaPlus } from 'react-icons/fa';
import { MdRefresh } from 'react-icons/md';

function ClubMembersTable({ title, max = 0 }) {
  let navigate = useNavigate();
  const size = useWindowSize();
  const {
    clubMembers,
    loadingMember,
    user,
    userTypes,
    getClubMembers,
    deleteMember,
  } = useContext(UserContext);

  const deleteUser = useCallback(value => {
    deleteMember(value);
    refresh();
  }, []);

  const refresh = useCallback(() => {
    getClubMembers();
  }, []);

  const columns = useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'name',
        Cell: ({ cell: { value }, row: { values, original } }) => {
          return (
            <Link
              href={`/club/activities?user_id=${original.id}`}
              textDecoration="underline"
            >
              {value}
            </Link>
          );
        },
      },
      {
        Header: 'Email',
        accessor: 'email',
      },
      {
        Header: 'Role',
        accessor: 'account_type_name',
        Cell: ({ cell: { value }, row: { values, original } }) => {
          return (
            <Box>
              <Badge>{value}</Badge>
            </Box>
          );
        },
      },
      {
        Header: '',
        accessor: 'id',
        Cell: ({ cell: { value }, row: { values, original } }) => {
          if (user.data.is_admin == true) {
            return (
              <HStack spacing={1}>
                <Tooltip label="Edit Member">
                  <IconButton
                    colorScheme="blue"
                    size="sm"
                    icon={<FaPen />}
                    onClick={() => {
                      localStorage.setItem(
                        'editMemberInfo',
                        JSON.stringify(original)
                      );
                      navigate('/club/member/edit');
                    }}
                  />
                </Tooltip>
                <Tooltip label="Delete Member">
                  <IconButton
                    colorScheme="red"
                    size="sm"
                    icon={<FaTrash />}
                    onClick={() => {
                      deleteUser(value);
                      console.log('Delete');
                    }}
                  />
                </Tooltip>
              </HStack>
            );
          } else {
            return '';
          }
        },
      },
    ],
    []
  );

  const ShowTable = () => {
    return (
      <DataTable
        size={size[0] <= 768 ? 'sm' : 'md'}
        _columns={columns}
        _data={clubMembers}
        filters={['name', 'email', 'is_admin']}
        title={title}
        // tableCaption={user.data.is_admin ? <InviteMemberButton /> : null}
        button={
          <HStack>
            <Tooltip label="Refresh List">
              <IconButton
                bgColor="cyan.600"
                color="white"
                size="sm"
                mr={8}
                icon={<MdRefresh />}
                onClick={() => {
                  refresh();
                }}
              />
            </Tooltip>
          </HStack>
        }
      />
    );
  };

  return <Box>{loadingMember ? <TableLoadingSkeleton /> : <ShowTable />}</Box>;
}

export default ClubMembersTable;
